/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-07 18:18:24
 * @Description:
 */

import "./ToolLeft.scss";
import "./ToolLeftMobile.scss";

import { FTLang, utils } from "src/utils";
import { IClassifyItem, IEffectItem } from "src/store/types";
import { IconCheck, IconHot, IconNew, IconSuccess } from "src/assets";
import React, { useEffect } from "react";
import { actionClickUp, actionGroupClickUp } from "src/analyze/actions";
import {
  useChoicenessList,
  useCurrentEffect,
  useCurrentEffectType,
  useCurrentEffectTypeId,
  useEffectClassifyMap,
  useLanguage
} from "src/hooks";

import { FTImageStore } from "src/store";
import { FTScrollbar } from "src/components";
import clsx from "clsx";
import { urls } from "src/constant";

interface IState {
  loading: boolean;
  currentImage: File | null;
  currentEffect: any;
  effectList: any[];
}

interface IProps {
  className: string;
}

export default function ToolLeftMobile(props: IProps) {
  let { className } = props;
  let effectClassifyMap = useEffectClassifyMap();
  let choicenessList = useChoicenessList();
  let currentEffect = useCurrentEffect();
  let currentEffectTypeId = useCurrentEffectTypeId();
  let [effectList, setEffectList] = React.useState<IClassifyItem[]>([]);
  let effectRef = React.createRef<any>();
  let classify = React.createRef<any>();
  const { t } = useLanguage();
  const scrollRefBar = React.useRef<any>();
  React.useEffect(() => {
    setEffectList([
      // {
      //   title: "",
      //   list: effectClassifyMap.map((item) => item.list).flat(),
      //   key: "tag_all",
      //   id: "1",
      //   isNew: false,
      //   effectIds: [],
      //   lang: FTLang._("tag_all"),
      //   collapse: false
      // },
      ...effectClassifyMap
    ]);
  }, [effectClassifyMap, choicenessList]);

  useEffect(() => {
    if (currentEffect) {
      const id = currentEffect.id;
      const ele = document.querySelector(`.effect${id}`);
      if (ele) {
        const eleRect = ele.getBoundingClientRect();
        if (eleRect.left + eleRect.width > window.innerWidth) {
          effectRef.current &&
            (effectRef.current as any).scrollMove(
              "right",
              eleRect.left + eleRect.width * 2 - window.innerWidth
            );
        } else if (eleRect.left < 0) {
          effectRef.current &&
            (effectRef.current as any).scrollMove("left", -eleRect.left);
        }
      }
    }
    if (currentEffectTypeId) {
      const id = effectList.findIndex(
        (item) => item.id === currentEffectTypeId
      );
      if (id > 0) {
        const ele = document.getElementById(`classify_${effectList[id].id}`);
        if (ele) {
          const eleRect = ele.getBoundingClientRect();
          const scrollLeft = scrollRefBar.current?.scrollLeft ?? 0;

          if (eleRect.left + eleRect.width > window.innerWidth) {
            scrollRefBar.current?.scroll({
              left:
                eleRect.left +
                eleRect.width * 2 -
                window.innerWidth +
                scrollLeft,
              behavior: "smooth"
            });
          } else if (eleRect.left < 0) {
            scrollRefBar.current?.scroll({
              left: eleRect.left + scrollLeft,
              behavior: "smooth"
            });
          }
        }
      }
    }
  }, [currentEffect, currentEffectTypeId]);

  const setEffectProcess = (i: IEffectItem, e: any) => {
    if (FTImageStore.currentImage) {
      actionClickUp(utils.toFixed(i.id, 0), i.enName, {
        button_position: "goart_effect",
        category_id: i.classifyId,
        category_name: i.firstCategoryEn
      });
      FTImageStore.getGoartEffect(i);
      // 如果特效未完全显示，需要使特效完全显示
      let eleRect = e.target.getBoundingClientRect();
      if (eleRect.left + eleRect.width > window.innerWidth) {
        scrollRefBar.current &&
          (scrollRefBar.current as any).scrollMove?.(
            "right",
            eleRect.left + eleRect.width * 2 - window.innerWidth
          );
      } else if (eleRect.left < 0) {
        scrollRefBar.current &&
          (scrollRefBar.current as any).scrollMove?.("left", -eleRect.left);
      }
    }
  };

  const handleOpenImage = async (e: any) => {
    if (e.target && e.target.classList.value.includes("open")) {
      FTImageStore.uploadImage("goart_left_change_image");
    }
  };

  const handleClick = (item: IClassifyItem) => {
    actionGroupClickUp(item.id, item.enName);
    FTImageStore.currentClassifyId = item.id;
    setTimeout(() => {
      const span = document.querySelector(".toolleft_effect_title span.active");
      if (span) {
        const eleRect = span.getBoundingClientRect();
        const scrollLeft = scrollRefBar.current?.scrollLeft ?? 0;
        const scrollWidth = scrollRefBar.current?.scrollWidth ?? 0;
        const clientWidth = scrollRefBar.current?.clientWidth ?? 0;

        if (eleRect.left + eleRect.width > window.innerWidth) {
          scrollRefBar.current?.scroll({
            left:
              eleRect.left + eleRect.width * 2 - window.innerWidth + scrollLeft,
            behavior: "smooth"
          });
        } else if (eleRect.left < 0) {
          scrollRefBar.current?.scroll({
            left: eleRect.left + scrollLeft,
            behavior: "smooth"
          });
        }
      }
    });
  };

  const currentClass =
    currentEffectTypeId === "" ? effectList[0]?.id : currentEffectTypeId;
  return (
    <div className={`toolleft toolleft_mobile ${className}`}>
      <FTScrollbar>
        <div className='toolleft_effect_title' ref={scrollRefBar}>
          {effectList &&
            effectList.map((item: any) => {
              return (
                <span
                  onClick={() => handleClick(item)}
                  id={`classify_${item.id}`}
                  className={clsx(currentClass === item.id && "active")}>
                  {item.title}
                </span>
              );
            })}
        </div>
      </FTScrollbar>
      <div className='toolleft_effect'>
        <div>
          {effectList &&
            effectList.map((classifyItem) => {
              if (classifyItem.id === currentClass) {
                return (
                  <FTScrollbar
                    onRef={effectRef}
                    className='toolleft_effect_scroll'>
                    <div className='toolleft_effect_scroll_list'>
                      {classifyItem.list.map((item: any) => {
                        return (
                          <div
                            key={item.id}
                            className={`toolleft_effect_item effect${item.prodid || item.id} ${item.id === currentEffect?.id ? "toolleft_effect_item_active" : ""}`}
                            onClick={(e) => setEffectProcess(item, e)}>
                            <div className='toolleft_effect_item_effect'>
                              <img
                                src={item.url}
                                alt={item.name}
                                className='loading-skeleton-back2'
                              />
                              <div className='toolleft_effect_item_check'>
                                <div className='toolleft_effect_item_check_wrapper'>
                                  <IconCheck />
                                </div>
                              </div>
                            </div>
                            <span className='toolleft_effect_item_title'>
                              {item.name}
                            </span>
                            {(item.isNew || item.isHot) && (
                              <div className='toolleft_effect_item_new'>
                                {item.isHot && <IconHot />}
                                {item.isNew && classifyItem.needShowNew && <IconNew />}
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <div
                        style={{
                          width: "16px",
                          height: "1px",
                          flexShrink: 0
                        }}></div>
                    </div>
                  </FTScrollbar>
                );
              } else {
                return <></>;
              }
            })}
        </div>
      </div>
    </div>
  );
}
