export const LANUGAGE_ARRAY = [
  {
    key: 'zh_CN',
    hrefLang: 'zh-cn',
    languageKey: 'cn',
    languageNum: 7,
  },
  {
    key: 'zh_TW',
    languageKey: 'tw',
    hrefLang: 'zh-tw',
    languageNum: 8,
  },
  {
    key: 'en_US',
    languageKey: 'en',
    hrefLang: 'en-us',
    languageNum: 1,
  },
  {
    key: 'es_ES',
    languageKey: 'es',
    hrefLang: 'es-es',
    languageNum: 2,
  },
  {
    key: 'pt_BR',
    languageKey: 'pt',
    hrefLang: 'pt-br',
    languageNum: 3,
  },
  {
    key: 'ru_RU',
    languageKey: 'ru',
    hrefLang: 'ru-ru',
    languageNum: 4,
  },
  {
    key: 'fr_FR',
    languageKey: 'fr',
    hrefLang: 'fr-fr',
    languageNum: 5,
  },
  {
    key: 'de_DE',
    languageKey: 'de',
    hrefLang: 'de-de',
    languageNum: 6,
  },
  {
    key: 'ja_JP',
    languageKey: 'jp',
    hrefLang: 'ja-jp',
    languageNum: 9,
  },
  {
    key: 'id_ID',
    languageKey: 'id',
    hrefLang: 'id-id',
    languageNum: 10,
  },
  // {
  //   key: 'it_IT',
  //   languageKey: 'it',
  //   hrefLang: 'it-it',
  //   languageNum: 11,
  // },
  // {
  //   key: 'th_TH',
  //   languageKey: 'th',
  //   hrefLang: 'th-th',
  //   languageNum: 12,
  // },
  // {
  //   key: 'vi_VN',
  //   languageKey: 'vi',
  //   hrefLang: 'vi-vn',
  //   languageNum: 13,
  // },
  // {
  //   key: 'tr_TR',
  //   languageKey: 'tr',
  //   hrefLang: 'tr-tr',
  //   languageNum: 14,
  // },
  // {
  //   key: 'pl_PL',
  //   languageKey: 'pl',
  //   hrefLang: 'pl-pl',
  //   languageNum: 15,
  // },
  // {
  //   key: 'nl_NL',
  //   languageKey: 'nl',
  //   hrefLang: 'nl-nl',
  //   languageNum: 16,
  // },
];

export const SHOW_LANGUAGE_ARRAY = [
  "zh_CN",
  "zh_TW",
  "en_US",
  "ja_JP",
  "es_ES",
  "pt_BR",
  "ru_RU",
  "fr_FR",
  "de_DE",
  "id_ID",
  // "it_IT",
  // "th_TH",
  // "vi_VN",
  // "tr_TR",
  // "pl_PL",
  // "nl_NL"
]
