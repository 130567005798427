import './style.scss';

/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 16:16:41
 * @Description:
 */
import { useImperativeHandle, useRef, useState } from 'react';

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import clsx from 'clsx';

function FTScrollbar(props: any) {
  const ftScrollbar: any = useRef(null);
  const [showTop, setShowTop] = useState(false);
  let myProps = { ...props };

  myProps.className =
    'ft_scrollbar ' +
    (myProps.className ? myProps.className : '') +
    ' ' +
    (myProps.theme ? myProps.theme : '');
  if (props.hasOwnProperty('isShowTop')) delete myProps.isShowTop;
  if (props.hasOwnProperty('onRef')) delete myProps.onRef;

  const onScroll = (e: any) => {
    if (!props.isShowTop) return;
    let scrollTop = ftScrollbar.current.getScrollTop();
    const shoTopLimit = 50;
    if (scrollTop < shoTopLimit) {
      if (showTop) setShowTop(false);
    } else if (scrollTop >= shoTopLimit) {
      if (!showTop) setShowTop(true);
    }
    myProps.onScroll && myProps.onScroll(e);
  };

  const scrollMove = (type: any, distance: number) => {
    let scrollTop = ftScrollbar.current.getScrollTop();
    let scrollLeft = ftScrollbar.current.getScrollLeft();
    switch (type) {
      case 'left':
        // ftScrollbar.current.scrollLeft(scrollLeft - distance)
        // 实现平滑滚动
        ftScrollbar.current.view.scroll({
          top: scrollTop,
          left: scrollLeft - distance,
          behavior: 'smooth',
        });
        break;
      case 'right':
        // ftScrollbar.current.scrollLeft(scrollLeft + distance)
        ftScrollbar.current.view.scroll({
          top: scrollTop,
          left: scrollLeft + distance,
          behavior: 'smooth',
        });
        break;
      case 'top':
        // ftScrollbar.current.scrollTop(scrollTop - distance)
        ftScrollbar.current.view.scroll({
          top: scrollTop - distance,
          left: scrollLeft,
          behavior: 'smooth',
        });
        break;
      case 'bottom':
        // ftScrollbar.current.scrollTop(scrollTop + distance)
        ftScrollbar.current.view.scroll({
          top: scrollTop + distance,
          left: scrollLeft,
          behavior: 'smooth',
        });
        break;
    }
  };
  // 将Scrollbars实例返回给父组件
  useImperativeHandle(props.onRef, () => {
    return {
      scrollMove: scrollMove,
      current: ftScrollbar.current.view,
    };
  });

  return (
    <div className={clsx('ft_scrollbar_wrap', props.className)}>
      <Scrollbars
        renderTrackHorizontal={(props: any) => (
          <div {...props} className='track-horizontal' />
        )}
        renderTrackVertical={(props: any) => (
          <div {...props} className='track-vertical' />
        )}
        renderThumbVertical={(props: any) => (
          <div {...props} className='thumb-vertical' />
        )}
        renderThumbHorizontal={(props: any) => (
          <div {...props} className='thumb-horizontal' />
        )}
        onScroll={onScroll}
        {...myProps}
        ref={ftScrollbar}></Scrollbars>
      {/* {showTop && (
        <div className="go_top_btn" onClick={goTop}>
          <FTSvg icon="iconi-top" />
        </div>
      )} */}
    </div>
  );
}

const FTScrollbarRef = React.forwardRef(FTScrollbar);
export default FTScrollbarRef;