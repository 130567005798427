/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 14:00:19
 * @Description:
 */
import './ToolLeftSearchTags.scss';

import { useEffectSelectTags, useEffectTags, useLanguage } from 'src/hooks';

import FTImageStore from 'src/store/FTImageStore';
import { IconArrawDown } from 'src/assets';
import React from 'react';
import clsx from 'clsx';

interface Iprops {
  handleChoseTags?: (tag) => void;
}

export default function ToolLeftSearchTags(props: Iprops) {
  const { handleChoseTags } = props;
  const effectTags = useEffectTags();
  const selectTags = useEffectSelectTags();
  const [effectTagsList, setEffectTagsList] = React.useState<any>([]);
  const [expend, setExpend] = React.useState(true);
  const { t } = useLanguage();

  React.useEffect(() => {
    if (expend) {
      setEffectTagsList([...effectTags]);
    } else {
      setEffectTagsList(effectTags.slice(0, 6));
    }
  }, [effectTags, expend]);

  const handleChooseTags = (tag: any) => {
    // actionClickUp(tag, tag)
    handleChoseTags && handleChoseTags(tag);
    
  };
  return (
    <div className='search_tags'>
      <div className='search_tags_title'>{t("tags_popover_title")}</div>
      <div className='search_tags_content'>

        {effectTagsList.map((item: any) => {
          return (
            <span
              key={item}
              className={clsx(
                'search_tags_item',
                (selectTags.length && selectTags.indexOf(item) !== -1) && 'active',
              )}
              onClick={() => handleChooseTags(item)}>
              {item}
            </span>
          );
        })}
        {/* <div
        className={clsx("search_tags_more", expend && "expend")}
        onClick={() => setExpend((x) => !x)}
      >
        <IconArrawDown />
      </div> */}
      </div>
    </div>
  );
}
