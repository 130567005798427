/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2022-04-27 14:20:07
 * @Description: 
 */
// @ts-nocheck
 import FTAnalyticBase from "./FTAnalyticBase";
 class FTGoogleAnalytic extends FTAnalyticBase {
   constructor() {
     super();
     this.getGTMSdk();
   }
   getGTMSdk() {
     (function(w, d, s, l, i) {
       w[l] = w[l] || [];
       w[l].push({
         "gtm.start": new Date().getTime(),
         event: "gtm.js"
       });
       var f = d.getElementsByTagName(s)[0],
         j = d.createElement(s),
         dl = l !== "dataLayer" ? "&l=" + l : "";
       j.async = true;
       j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
       f.parentNode.insertBefore(j, f);
     })(
       window,
       document,
       "script",
       "dataLayer",
       "GTM-5763HM"
     );
   }
   addPV(info) {
     let { url, userId, token, isVip, adblock, specialContent } = info;
     window.dataLayer.push({
       event: "trackPageView",
       url,
       locale: "zh-cn",
       userId: userId || "",
       token: token || "",
       isVip,
       visitTimes: "",
       visitDuration: "",
       usingDuration: "",
       appVersion: "1.0.0",
       adblock,
       specialContent,
       product: "h5-app",
       host: document.location.host,
       apiKey: "fotor"
     });
   }
   addEvent(info) {
     let {
       category,
       action,
       label,
       value,
       locale,
       userId,
       token,
       isVip,
       adblock,
       specialContent
     } = info;
     window.dataLayer.push({
       event: "trackEvent",
       category: category || "count",
       action: action || "tools",
       label,
       value,
       locale,
       userId,
       token,
       isVip,
       visitTimes: "",
       visitDuration: "",
       usingDuration: "",
       appVersion: "1.0.0",
       adblock: adblock,
       specialContent: specialContent,
       product: "h5-app",
       host: document.location.host,
       apiKey: "fotor"
     });
   }
 }
 export default FTGoogleAnalytic;
 