import { canvasToBlob, imageLoader, utils, withPublicUrl } from "./index";

import { CheckStatus } from "src/constant";
import { downloadFileWithUrl } from "src/server/api";

enum CheckError {
  noFace = "avatar_no_result4",
  tooSmall = "avatar_no_result1",
  tooBig = "avatar_no_result2",

}

export default class CutOutAvatar {
  preloadFaceModelPromise: Promise<any> | null = null;
  tryPreloadFaceModel() {
    if (!this.preloadFaceModelPromise) {
      this.preloadFaceModelPromise = this.preloadFaceModel();
    }
    return this.preloadFaceModelPromise;
  }

  async startProcess(file: File) {
    await this.tryPreloadFaceModel();
    return this.checkImage(file);
  }

  async initLoad() {
    await this.tryPreloadFaceModel();
  }

  async checkImage(file) {
    const faceApi = await import("face-api.js");
    const url = window.URL.createObjectURL(file);
    const image = await imageLoader(url);
    const { detectAllFaces, SsdMobilenetv1Options, TinyFaceDetectorOptions } =
      faceApi;
    let fullFaceDescriptions1 = await detectAllFaces(
      image,
      new SsdMobilenetv1Options()
    )
      .withFaceLandmarks()
      .withFaceDescriptors();
    if (fullFaceDescriptions1?.length === 0) {
      fullFaceDescriptions1 = await detectAllFaces(
        image,
        new TinyFaceDetectorOptions()
      )
        .withFaceLandmarks()
        .withFaceDescriptors();
    }
    console.log("fullFaceDescriptions1", fullFaceDescriptions1);
    if (fullFaceDescriptions1?.length === 1) {
      let { result, rate } = this.checkFace(fullFaceDescriptions1[0]);
      if (result === 1) {
        return {
          result: false,
          message: CheckError.tooSmall
        };
      }
      if (result === 2) {
        return {
          result: false,
          message: CheckError.tooBig
        };
      }

      const result2 = await this.cutOut(fullFaceDescriptions1[0], file);
      return {
        result: true,
        file: result2,
        message: ""
      };
    }
    if (fullFaceDescriptions1?.length > 1) {
      // 选取最大的脸
      const areaMap = fullFaceDescriptions1.map(item=>item.alignedRect.box.width * item.alignedRect.box.height);
      const maxIndex = areaMap.indexOf(Math.max(...areaMap));
      let { result, rate } = this.checkFace(fullFaceDescriptions1[maxIndex]);

      if (result === 1) {
        return {
          result: false,
          message:  CheckError.tooSmall
        };
      }
      if (result === 2) {
        return {
          result: false,
          message:  CheckError.tooBig
        };
      }
      const result2 = await this.cutOut(fullFaceDescriptions1[0], file);
      return {
        result: true,
        file: result2,
        message: ""
      };
    }
    return {
      result: false,
      message: CheckError.noFace
    };
  }

  async cutOut(info, file: File) {
    const { _height, _width, _x, _y } = info.alignedRect["_box"];
    let result = await this.catoutImage(file, { _height, _width, _x, _y });
    // let findIndex = this.fileArr.findIndex(items => items.uuid === item.uuid);
    // if (findIndex > -1) {
    //   this.fileArr[findIndex].file = result;
    // }
    return result;
  }

  checkFace(obj) {
    const {
      _box: { _height, _width },
      imageHeight,
      imageWidth
    } = obj.alignedRect;
    const faceArea = Math.ceil(_width) * Math.ceil(_height);
    const imageArea = Math.ceil(imageWidth) * Math.ceil(imageHeight);
    const rate = Number((faceArea / imageArea).toFixed(2));
    if (rate < 0.01) {
      return { result: 1, rate };
    }
    if (rate > 0.5) {
      return { result: 2, rate };
    }
    return { result: 0, rate };
  }

  private async preloadFaceModel(tryFirst: boolean = true) {
    try {
      if (!this.preloadFaceModelPromise) {
        const dir = withPublicUrl("/face");
        const faceApi = await import("face-api.js");
        const { nets, SsdMobilenetv1Options, detectAllFaces } = faceApi;
        await Promise.all([
          nets.ssdMobilenetv1.loadFromUri(dir),
          nets.faceLandmark68Net.loadFromUri(dir),
          nets.faceRecognitionNet.loadFromUri(dir),
          nets.tinyFaceDetector.loadFromUri(dir)
        ]);
        const testImage = withPublicUrl("img/1-frontal-male.jpg");
        if (tryFirst && testImage) {
          const { data: imageFile } = await downloadFileWithUrl({
            url: testImage
          });
          const blobUrl = URL.createObjectURL(imageFile);
          let localImage: any = document.createElement("img");
          localImage.crossOrigin = "anonymous";
          localImage.src = blobUrl;
          await new Promise((resolve) => {
            localImage.onload = resolve;
          });
          // 试着识别一张图（单纯配置模型路径只是预设配置，没有真正加载模型）
          await detectAllFaces(localImage, new SsdMobilenetv1Options())
            .withFaceLandmarks()
            .withFaceDescriptors();

          // gc
          localImage = null;
          URL.revokeObjectURL(blobUrl);
        }
      }
    } catch (error) {
      console.log(error);
      this.preloadFaceModelPromise = null;
    }
  }
  getImageFromFile(
    file: File,
    resizeWidth?: number,
    resizeHeight?: number,
    limitSize?: number
  ): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      let image = document.createElement("img");
      image.setAttribute("crossOrigin", "anonymous");
      image.src = window.URL.createObjectURL(file);
      image.onload = function () {
        let { width, height } = image;
        let scale = 1;
        if (limitSize) {
          scale = utils.aspectFitScale(width, height, limitSize);
        }
        const canvas = document.createElement("canvas");
        canvas.width = resizeWidth || width * scale;
        canvas.height = resizeHeight || height * scale;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(
          image,
          0,
          0,
          width,
          height,
          0,
          0,
          canvas.width,
          canvas.height
        );
        resolve(canvas);
      };
    });
  }
  async catoutImage(files, info) {
    let canvas = await this.getImageFromFile(files);
    const { _x, _y, _height, _width } = info;
    let getWidth = Math.ceil(_width * 0.3);
    let getHeight = Math.ceil(_height * 0.3);
    let getX = Math.ceil(_x) - getWidth;
    let getY = Math.ceil(_y) - getHeight;
    let setWidth = Math.ceil(_width) + getWidth * 2;
    let setHeight = Math.ceil(_height) + getHeight * 2;
    if (getX < 0) {
      let more = Math.abs(getX);
      getX = 0;
      setWidth += more;
    }
    if (getY < 0) {
      let more = Math.abs(getY);
      getY = 0;
      setHeight += more;
    }
    if (setWidth + getX > canvas.width) {
      setWidth = canvas.width - getX;
    }
    if (setHeight + getY > canvas.height) {
      setHeight = canvas.height - getY;
    }
    let newCanvas = document.createElement("canvas");
    newCanvas.width = setWidth;
    newCanvas.height = setHeight;
    let ctx = newCanvas.getContext("2d");
    ctx!.fillStyle = "#ffffff";
    ctx!.fillRect(0, 0, canvas.width, canvas.height);
    ctx!.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      -getX,
      -getY,
      canvas.width,
      canvas.height
    );
    // let decode = newCanvas.toDataURL('image/jpeg');
    const blob = await canvasToBlob(newCanvas, "image/jpeg", 1);
    const file = new File([blob], files.name, { type: "image/jpeg" });
    const obj = await this.handleImage(file);
    return obj.file;
  }

  async handleImage(file) {
    let url = window.URL.createObjectURL(file);
    let { image } = await utils.getImageFromUrl(url);

    if (Math.max(image.width, image.height) > 768) {
      let scale = 768 / Math.max(image.width, image.height);
      let canvas = await this.getImageFromFile(
        file,
        image.width * scale,
        image.height * scale
      );
      // 如果是有透明的图片，需要把透明的内容处理成白色底
      let newCanvas = document.createElement("canvas");
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;
      let ctx = newCanvas.getContext("2d");
      ctx!.fillStyle = "#ffffff";
      ctx!.fillRect(0, 0, newCanvas.width, newCanvas.height);
      ctx!.drawImage(canvas, 0, 0, newCanvas.width, newCanvas.height);
      // 额外的dataURL转换避免直接toBlob跨域污染报错
      // let decode = newCanvas.toDataURL('image/jpeg');
      // file = (await utils.dataURLtoFile(decode, file.name, 'image/jpeg')) as File;
      const blob = await canvasToBlob(newCanvas, "image/jpeg", 1);
      file = new File([blob], file.name, { type: "image/jpeg" });
    } else {
      let canvas = await this.getImageFromFile(file, image.width, image.height);
      let newCanvas = document.createElement("canvas");
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;
      let ctx = newCanvas.getContext("2d");
      ctx!.fillStyle = "#ffffff";
      ctx!.fillRect(0, 0, newCanvas.width, newCanvas.height);
      ctx!.drawImage(canvas, 0, 0, newCanvas.width, newCanvas.height);
      const blob = await canvasToBlob(newCanvas, "image/jpeg", 1);
      file = new File([blob], file.name, { type: "image/jpeg" });
    }

    return {
      file,
      url: window.URL.createObjectURL(file),
      status: CheckStatus.pending,
      result:
        image.width < 256 && image.height < 256 ? CheckError.tooSmall : null
    };
  }
}
