/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-08 15:56:28
 * @Description:
 */
import "./EffectListCollapseItem.scss";

import { IconCheck, IconHot, IconNew, IconSuccess } from "src/assets";
import { imageLoadingClassName, utils } from "src/utils";

import { FTImageStore } from "src/store";
import { IEffectItem } from "src/store/types";
import React from "react";
import { actionClickUp } from "src/analyze/actions";
import clsx from "clsx";
import { urls } from "src/constant";
import { useLanguage } from "src/hooks";

interface Iprops {
  effect: IEffectItem;
  active: boolean;
  needCollapse: boolean;
  showNew?: boolean;
}
export default function EffectListCollapseItem(props: Iprops) {
  let { effect, active, needCollapse = true, showNew = true } = props;
  const { t } = useLanguage();

  const handleChooseEffect = () => {
    actionClickUp(utils.toFixed(effect.id as number, 0), effect.enName, {
      boolean1:
        FTImageStore.searchText !== "" &&
        FTImageStore.selectEffectTags.length === 0,
      boolean2: FTImageStore.selectEffectTags.length !== 0,
      button_position: "goart_effect",
      category_id: effect.classifyId,
      category_name: effect.firstCategoryEn
    });
    FTImageStore.getGoartEffect(effect, "goart_effect");
  };
  return (
    <div
      data-name={effect.name}
      data-id={effect.prodId || effect.id}
      id={`fotor_${effect.id}`}
      className={clsx(
        "effect_collapse_item",
        active && "active",
        needCollapse && effect.name.replace(/ /g, () => "_")
      )}
      onClick={handleChooseEffect}>
      <div
        className={clsx("effect_collapse_item_image", imageLoadingClassName())}>
        <img
          src={effect.url}
          alt={effect.name}
          className='loading-skeleton-back2'
        />
        <div className={clsx("effect_collapse_item_check")}>
          <div className='effect_collapse_item_check_wrapper'>
            <IconCheck />
          </div>
        </div>
        {(effect.isNew || effect.isHot) && (
          <div className='effect_collapse_item_new'>
            {effect.isHot && <IconHot />}
            {effect.isNew && showNew && <IconNew />}
          </div>
        )}
      </div>
      <span className='effect_collapse_item_title'>{effect.name}</span>
    </div>
  );
}
