/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 11:37:47
 * @Description:
 */
const urls = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  paymentSdk: process.env.REACT_APP_PAYMENT_SDK,
  loginSdk: process.env.REACT_APP_SIGN_SDK,
  sensorUrl: process.env.REACT_APP_SA_DATA_URL,
  assetsUrl: process.env.REACT_APP_ASSETS_URL
};
export default urls;
