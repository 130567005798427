/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 19:49:33
 * @Description:
 */

import "./header.scss";
import "fotor-website-header/websiteHeaderNoCss.css";

import { AD_ID, FTADManager, LANGUAGE_TYPE, utils } from "src/utils";
import initTopFixedHeight from "src/utils/initTopFixedHeight";
import { LANUGAGE_ARRAY, PROJECT_TYPE } from "src/constant";
import React, { useEffect, useLayoutEffect, useState } from "react";
import WebsiteHeader, {
  ToolHeader
} from "fotor-website-header/websiteHeaderNoCss.bundle";
import { useIsMobile, useLanguage } from "src/hooks";

import FTUserStore from "src/store/FTUserStore";


const Logout = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
  FTUserStore.loginOut();
};

// class Header extends React.Component {
//   state = {
//     isLogin: false,
//     isAndroidOriOS: false,
//   }
//   componentDidMount() {
//     autorun(() => {
//       let { userInfo } = FTUserStore
//       let { ios, android } = utils.myBrowserOS();
//       this.setState({
//         isAndroidOriOS: ios|| android,
//         isLogin: !!userInfo
//       })
//     })

//   }

//   handleSign = () => {
//     FTUserStore.showLogin({
//       isSignIn: true,
//       options: {
//         position: "goart_signin"
//       },
//       isShowScenses: true,
//       otherParam: {
//         scenesId: "014"
//       }
//     })
//   }

//   handleSignUp = () => {
//     FTUserStore.showLogin({
//       isSignIn: false,
//       options: {
//         position: "goart_signup"
//       },
//       isShowScenses: true,
//       otherParam: {
//         scenesId: "014"
//       }
//     })
//   }

//   handleLinkToAICG = () => {
//     FTAnalytic.addAnalytic({
//       type: 'ev',
//       analyticType: "sa",
//       action: 'fotor_web$click_up$project_top_feature',
//       label: {
//         location: "goart",
//         button_position: "avatar"
//       }
//     })
//     window.open(getAIGCLink("toAvatar"))
//   }

//   render() {
//     let { isLogin, isAndroidOriOS } = this.state
//     return <div className="header">
//       <div className="header_left">
//         <FotorLogoIconNew />
//         <div className="link_to_aicg" onClick={() => this.handleLinkToAICG()}>
//           <IconLinkAigc />
//           <span className="link_to_aicg_text">
//             {FTLang._("text_to_image")}
//           </span>
//         </div>
//       </div>
//       <div className="header_right">
//         {
//           isAndroidOriOS && <div className="header_right_item">
//             <BtnOpenApp></BtnOpenApp>
//           </div>
//         }
//         {isLogin ?

//           <div className="header_right_item">
//             <Dropdown overlayClassName="header_right_dropdown" overlay={<Menu>
//               <Menu.Item key="user">
//                 <div className="header_right_user">
//                   <div className="header_right_user_img">
//                     <img src={FTUserStore.userInfo.headerUrl} alt="" />
//                   </div>
//                   <div className="header_right_user_info">
//                     <span className="header_right_user_info_name">{
//                       FTUserStore.userInfo.nickName
//                     }</span>
//                     <span className="header_right_user_info_email">{
//                       FTUserStore.userInfo.email
//                     }</span>
//                   </div>
//                 </div>
//               </Menu.Item>
//               <Menu.Item key="accout">
//                 <a className="header_right_logout" href={urls.baseUrl + "user/settings"} target="_blank" rel="noreferrer">{FTLang._("goart_myaccount")}</a>
//               </Menu.Item>
//               <Menu.Item key="logout">
//                 <span className="header_right_logout" onClick={Logout}>{FTLang._("goart_logout")}</span>
//               </Menu.Item>
//             </Menu>} trigger={['click']}>
//               <img src={FTUserStore.userInfo.headerUrl} alt="" />
//             </Dropdown>
//           </div>
//             : (isAndroidOriOS ? <>
//               <div className="header_right_item">
//                 <div className="header_right_noLogin">
//                   <span className="header_right_signin" onClick={() => this.handleSign()}> {FTLang._("goart_signin")} </span>
//                 </div>
//               </div></> :
//             <div className="header_right_item">
//               <div className="header_right_noLogin">
//                 <span className="header_right_signin" onClick={() => this.handleSign()}> {FTLang._("goart_signin")} </span>
//                 <span className="header_right_slider">/</span>
//                 <span className="header_right_signup" onClick={() => this.handleSignUp()}> {FTLang._("goart_signup")} </span>
//               </div>
//             </div>
//             )
//           }
//       </div>
//     </div>
//   }
// }

function Header() {
  const isMobile = useIsMobile();
  const { changeLanguage } = useLanguage();
  const [topMobileAppAd,setTopMoibleAppAd] = useState({
    rootId: "mobile_top_fotor_app_ad",
    isShow: true
  })
  useEffect(() => {
    // 准备广告
    FTADManager.ready();
    FTADManager.init();
    // 显示顶部广告
    utils.myBrowserOS().isMobile && utils.mobileCommonSdkLoaded(() => {
      const {
        createInstance: createFTTopAppAdInstance,
        setShow: setShowTopMobileAppAd
      } = window.mobileCommon.FTTopAppAdInstance();
      createFTTopAppAdInstance({
        root_id: topMobileAppAd.rootId,
        projectType: "editor",
        onShowStateChange: show => {
            //监听有无广告来控制操作按钮位置
          setTopMoibleAppAd({
            ...topMobileAppAd,
            isShow: show
          })
          initTopFixedHeight(show);
          // if(show){
            //调整位置
              // document.body.style.setProperty("--mobile-add-fixed-height", '74px');
          // }else{
            //恢复css样式
            // document.body.style.setProperty("--mobile-add-fixed-height", '0px');
          // }
        },
      });


    });

  }, []);



  const callback = (e: any) => {
    console.log("callback", e)
    switch (e.type) {
      case "login": // 登录和注册都是这个回调
        if ([0, 1, 2, 3, 5, 6].includes(e.res.code)) {
          FTUserStore.checkUserLogin();
        }
        if (e.res.code === 4) {
          utils.autoShowOpenAppConfirm()
        }
        break;
      case "logout":
        FTUserStore.loginOut();
        break;
      case "language":
        changeLanguage(e.value);
        break;
      default:
        break;
    }
  };
  const { language } = useLanguage();
  return (
    <>
    { topMobileAppAd.isShow&&<div id="mobile_top_fotor_app_ad" className="topMobileAppAd"></div>}
    <WebsiteHeader
      logoHref='https://www.fotor.com/'
      callback={callback}
      lang={language}
      openInNewTabLogo={true}
      className={`header-fotor`}
      customTheme='websiteHeader_custom'
      showOpenInApp={false}
      noNavMenu={false}
      displayLanguageMenu={LANUGAGE_ARRAY.map((item) => item.languageKey)}
      projectType={PROJECT_TYPE}
      ShowUpgradeButton={true}
      showMobileAd={true}
      theme='black'
      pageType='tool'
      themeStyleLogo={"whiteLogo"}
      adLocation={AD_ID.RESIDENT}
      env={
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "production"
          : "development"
      }
      style={{ background: "#1E1F22", color: "#fff" }}
      loginSensorsData={[
        { position: "goart_signin" },
        true,
        { scenesId: "014" }
      ]}
      signUpSensorData={[
        {
          position: "goart_signup"
        },
        true,
        {
          scenesId: "014"
        }
      ]}></WebsiteHeader>
    </>

  );
}

export default Header;
