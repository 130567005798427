/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2022-12-07 09:53:03
 * @Description:
 */

import "./index.scss";

import { useIsMobile, useLanguage } from "src/hooks";

import FTCanvasStore from "src/store/FTCanvasStore";
import FTLang from "src/utils/FTLang";
import { IconCompare } from "src/assets";
import React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { actionFeatureClickUp } from "src/analyze/actions";
import clsx from "clsx";

interface Iprops {
  needToolTip: boolean;
  theme: "dark" | "light";
  placement?: TooltipPlacement;
}
export default function FTimageCompare(props: Iprops) {
  let { needToolTip, theme = "dark", placement = "left" } = props;
  const { t } = useLanguage();
  const isMobile = useIsMobile();
  const handleCompare = (bool: boolean) => {
    // 如果为true 进行对比操作
    // 如果为 fakse 取消对比
    if(bool) {
      actionFeatureClickUp("compare");
    }
    FTCanvasStore.compare(bool);
  };
  return (
    <div
      className={clsx("ai_image_compare")}
      onMouseDown={() => handleCompare(true)}
      onMouseLeave={() => handleCompare(false)}
      onMouseUp={() => handleCompare(false)}
      onTouchStart={() => handleCompare(true)}
      onTouchEnd={() => handleCompare(false)}>
      {needToolTip ? (
        <Tooltip
          key='image_compare_tip'
          placement={placement}
          color='#FFFFFF'
          overlayClassName='ai_image_common_operate_tip'
          title={""}>
          <div className='ai_image_common'>
            <IconCompare />
            {!isMobile && <span>{t("goart_compare_tip")}</span>}
          </div>
        </Tooltip>
      ) : (
        <IconCompare key='image_compare' />
      )}
    </div>
  );
}
