import React from 'react';

export default function FTNoImageSkedon() {
  return (
    <div className='noimage_mobile'>
      <div className='mySwiper' style={{aspectRatio:"100 / 42.7"}}>
        <div className='loading-skeleton-back2'></div>
      </div>
      <div className='noimage_mobile_center'>
        <div className='loading-skeleton-back2'></div>
      </div>
    </div>
  );
}
