/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-02 14:58:41
 * @Description:
 */

const imageProcesserSize = {
  /**
   * 最高超分辨率
   */
  SUPPER_RESOLUTION_HIGH_ID: "2880",
  /**
   * 中等超分辨率
   */
  SUPPER_RESOLUTION_MEDIUM_ID: "1440",

  /**
   * 算法固定支持图片的大小，宽高一致（直接拉伸）
   */
  IMAGE_SIDE_VALUE: 720
};

export default imageProcesserSize;
