/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-02 16:55:10
 * @Description:
 */

import "./FTDownloadLoading.scss";

import { AD_ID, FTLang, rangNumber } from "src/utils";
import React, { useEffect, useState } from "react";
import { useIsMobile, useLanguage } from "src/hooks";

import ADWrapper from "../ADWrapper/ADWrapper";
import FTButton from "../FTButton";
import { FTCanvasStore } from "src/store";
import FTProgress from "../FTProgress/FTProgress";
import { IconClose } from "src/assets";
import clsx from "clsx";

interface IProps {
  loading: boolean;
  progress?: number;
}
function FTDownloadLoading(props: IProps) {
  const { loading, progress } = props;
  const [percent, setPercent] = React.useState(progress || 0);
  const isMobile = useIsMobile();
  const timer = React.useRef<any>(null);
  const [adShow,setAdShow] = useState(true);
  const { t } = useLanguage();
  // const startInterval = () => {
  //   if(timer.current) clearInterval(timer.current);

  //   timer.current = setInterval(() => {
  //     setPercent((percent) => {
  //       percent = percent + 3;
  //       return rangNumber(0, 100, percent);
  //     });
  //   }, 1000);
  // }
  // const setDownLoadVisible = useCanvasStoreFN(state=>state.setDownLoadVisible)
  // React.useEffect(() => {
  //   startInterval();
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }, []);
  React.useEffect(() => {
    setPercent(progress || 0);
  }, [progress]);
  React.useEffect(() => {
    if (!loading) {
      clearInterval(timer.current);
      timer.current = null;
    }
    const downloadAgain = document.querySelector(".download_again");
    downloadAgain?.addEventListener("click", () => {
      // setPercent(2);
      // startInterval();

      FTCanvasStore.downloadFullSize();
    });
  }, [loading]);

  const handleClose = () => {
    FTCanvasStore.setDownLoadVisible(false);
  };
  const newProgress = rangNumber(0, 100, percent);
  const handleAdCallback = (res)=>{
    console.log("handleAdCallback",res);
    if(res.adDomId === AD_ID.EXPORT && res.type==="loaded"){
      setAdShow(res.haveAd)
    }
  }
  return (
    <div
      className={clsx(
        "download_loading",
        isMobile && "download_loading_mobile"
      )}>
      <div className={clsx("download_loading_header", "loading-skeleton")} style={{display:adShow?"flex":"none"}}>
        <ADWrapper adId={AD_ID.EXPORT} type='export' callback={handleAdCallback} />
      </div>
      <div className={clsx("download_loading_content")}>
        {loading ? (
          <>
            <div className='download_loading_text'>
              {t("goart_downloading")}
            </div>
            <div className='download_loading_process'>
              {
                <FTProgress
                  progress={newProgress}
                  startColor='#26FFA5'
                  endColor='#2643FF'
                />
              }
            </div>
          </>
        ) : (
          <>
            <div className='download_loading_title'>
              {t("download_success_title")}
            </div>
            <div
              className='download_loading_desc'
              dangerouslySetInnerHTML={{
                __html: t("download_success_desc", {
                  link: `<span class='download_again'>${t("download_success_link")}</span>`
                })
              }}></div>
            <div className='download_loading_btn'>
              <FTButton type='primary' onClick={handleClose}>
                {t("download_success_continue")}
              </FTButton>
            </div>
          </>
        )}
      </div>
      {!loading && (
        <div className='download_loading_close' onClick={handleClose}>
          <IconClose />
        </div>
      )}
    </div>
  );
}

export default FTDownloadLoading;
