/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 14:00:19
 * @Description:
 */

import "./ToolLeftSearchTags.scss";

import React, { useEffect, useState } from "react";
import { imageLoadingClassName, withPublicUrl, withUnit } from "src/utils";
import { useEffectClassifyMap, useImageStoreFN, useLanguage } from "src/hooks";

import FTImageStore from "src/store/FTImageStore";
import { IClassifyItem } from "src/store/types";
import { IconRight } from "src/assets";
import TipCard from "src/components/tip-card";
import { Tooltip } from "antd";
import { actionGroupClickUp } from "src/analyze/actions";
import clsx from "clsx";
import { toolTipProps } from "src/constant";
import useScroll from "../useScrollBar";

const arr = new Array(5).fill(0);
const key = "goart_avatar_tip";
export default function ToolLeftSearchTagsDown() {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const classifyList = useEffectClassifyMap();
  const { t, language } = useLanguage();
  const [showToolTip, setShowToolTip] = useState(false);
  const [isFirst, setIsFirst] = useState(localStorage.getItem(key) === null);
  const currentClassifyId = useImageStoreFN((state) => state.currentClassifyId);
  const loadingGlobalEffect = useImageStoreFN(
    (state) => state.loadingGlobalEffect
  );
  const { prevDisabled, nextDisabled, handleNext, handlePrev, handleActive } =
    useScroll(scrollRef, 32, [classifyList, language,loadingGlobalEffect]);
  const timer = React.useRef<any>(null);
  useEffect(() => {
    if (currentClassifyId) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      timer.current = setTimeout(() => {
        console.log("handleActive");
        handleActive(
          classifyList.findIndex((item) => item.id === currentClassifyId) + 2
        );
      }, 500);
    }
  }, [classifyList, currentClassifyId, language]);


  const handleChooseTags = (tag: IClassifyItem, index) => {
    if (!tag.isAvatar) {
      setShowToolTip(false);
    } else if (isFirst) {
      setIsFirst(false);
      localStorage.setItem(key, "1");
      setShowToolTip(true);
    }
    // actionClickUp(tag.id, tag.title, {
    //   button_position: "goart_effect_classify"
    // });
    // actionClickUp(tag, tag)
    FTImageStore.chooseSelectClassify(tag.id);
    handleActive(index + 2);
    actionGroupClickUp(tag.id, tag.enName);
  };
  const handlePrev2 = () => {
    handlePrev();
  };
  const handleSelectAll = () => {
    if (FTImageStore.timerChooseClass) {
      clearTimeout(FTImageStore.timerChooseClass);
      FTImageStore.timerChooseClass = null;
    }
    FTImageStore.clearSelectTags();
    FTImageStore.searchText = "";
    FTImageStore.currentClassify = "";
    FTImageStore.currentClassifyId = "";
    FTImageStore.search();
  };

  return (
    <Tooltip
      overlayInnerStyle={toolTipProps}
      placement='rightBottom'
      visible={showToolTip}
      overlayClassName='tooltip_help'
      align={{
        offset: [20, 0]
      }}
      title={
        <TipCard
          onClickGotIt={() => {
            setShowToolTip(false);
          }}
          videoSrc={withPublicUrl("video", "avatar_tip.mp4")}
          videoPoster={withPublicUrl("img", "avatar_tip.jpg")}
          title={t("avatar_tip_title")}
          desc={t("avatar_tip_desc")}
          gotItButtonText={t("avatar_tip_btn")}
        />
      }>
      <div className='search_tags_down'>
        <div
          className={clsx(
            "search_controller serarch_prev",
            prevDisabled && "search_controller_disabled"
          )}
          onClick={handlePrev2}>
          <div className='search_controller_icon'>
            <IconRight />
          </div>
        </div>

        <div className='search_tags_down_scroll' ref={scrollRef}>
          {loadingGlobalEffect ? (
            arr.map((_, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: withUnit(50),
                    height: withUnit(36),
                    marginRight: withUnit(10),
                    borderRadius: withUnit(4)
                  }}
                  className={clsx(imageLoadingClassName())}></div>
              );
            })
          ) : (
            <>
              <span
                key={"All"}
                className={clsx(
                  "search_tags_item",
                  currentClassifyId.trim() === "" && "active"
                )}
                onClick={handleSelectAll}>
                {t("tag_all")}
              </span>
              <div className='search_tags_divider'></div>
              {classifyList.map((item, index) => {
                return (
                  <span
                    key={item.title}
                    data-id={item.id}
                    className={clsx(
                      "search_tags_item",
                      currentClassifyId === item.id && "active"
                    )}
                    onClick={() => handleChooseTags(item, index)}>
                    {item.title}
                    {item.isNew && item.needShowNew && (
                      <i className='search_tags_item_new'></i>
                    )}
                  </span>
                );
              })}
            </>
          )}
        </div>

        <div
          className={clsx(
            "search_controller serarch_next",
            nextDisabled && "search_controller_disabled"
          )}
          onClick={handleNext}>
          <div className='search_controller_icon'>
            <IconRight />
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
