import { IconClose, IconDownload, IconWhitePro } from 'src/assets';
import React, { useEffect, useState } from 'react';
import { useIsMobile, useLanguage, useUserStoreFN } from 'src/hooks';

import FTButton from '../FTButton';
import { Popover } from 'antd';
import { actionTryDownload } from 'src/analyze/actions';
import clsx from 'clsx';
import { imageProcesserSize } from 'src/constant';
import styles from './DownloadWithPopover.module.scss';
import { utils } from 'src/utils';

export interface IDownloadWithPopoverProps {
  className?: string;
  btnClassName?: string;
  textDownload?: string;
  textDownloadFree?: string;
  textDownloadHD?: string;
  disabled?: boolean;
  loading?: boolean;
  placement?: 'left' | 'right';
  width?: number;
  height?: number;
  onDownloadPreview?: (type:string) => void;
  onDownloadHD?: (type:string) => void;
}

export default function DownloadWithPopover(props: IDownloadWithPopoverProps) {
  const {
    className,
    btnClassName,
    disabled,
    loading,
    width,
    height,
    onDownloadHD,
    onDownloadPreview,
  } = props;
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useLanguage();
  const triggerPosition = React.useRef({ x: 0, y: 0, width: 0, height: 0 });
  const [contentStyle, setContentStyle] = React.useState<
    React.CSSProperties | undefined
  >();
  const popoverRef = React.useRef<any>(null);
  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const isMobile = useIsMobile();
  const showFreeTry = useUserStoreFN((state) => state.showFreeTry);
  const activeBlackFriday = useUserStoreFN((state) => state.activeBlackFriday);
  const showDiscount = useUserStoreFN((state) => state.showDiscount);
  const isVip = useUserStoreFN((state) => state.isVip);
  const blackFridayGroupA = useUserStoreFN(state=>state.blackFridayGroupA)


  useEffect(() => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      triggerPosition.current = {
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
      };
    }
  }, []);
  const previewSize = React.useMemo(() => {
    if (!width || !height) return { width: 0, height: 0 };
    let smallScale = utils.aspectFitScale(
      width,
      height,
      imageProcesserSize.IMAGE_SIDE_VALUE,
    );
    return {
      width: Math.round(width * smallScale),
      height: Math.round(height * smallScale),
    };
  }, [width, height]);

  const hdSize = React.useMemo(() => {
    if (!width || !height) return { width: 0, height: 0 };
    let scale = utils.aspectFitScale(
      width,
      height,
      parseInt(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID),
    );
    return {
      width: Math.round(width * scale),
      height: Math.round(height * scale),
    };
  }, [width, height]);

  const handleShowModal = () => {
    if (disabled) return;
    if (loading) return;
    setPopoverVisible(true);
    actionTryDownload();
  };
  const handleClose = () => {
    setPopoverVisible(false);
  };

  const handleDownloadHD = () => {
    onDownloadHD?.(waterMarkText);
    setPopoverVisible(false);
  };
  const handleDownloadPreview = () => {
    onDownloadPreview?.(waterMarkText);
    setPopoverVisible(false);
  };
  const popoveProps: any = {
    showArrow: false,
  };
  const showVipIcon = isVip || showDiscount !== 0;
  const [waterMarkText, setWaterMarkText] = useState<string>("");
  React.useEffect(() => {
    let watermark = '';
    if (showDiscount !== 0) {
      watermark = 'download_hd_off';
      if(activeBlackFriday){
        watermark = 'download_hd_off_blackFriday';
      } else if(blackFridayGroupA){
        watermark = 'download_hd_free';
      }
    } else if (showFreeTry || blackFridayGroupA) {
      watermark = 'download_hd_free';
    } else if (isVip) {
      watermark = 'download_hd_need_pro';
    }
    setWaterMarkText(watermark)
  }, [showFreeTry, showDiscount, isVip,activeBlackFriday,blackFridayGroupA]);
  const getButtonDescDiscount = () => {
    if(activeBlackFriday) {
      return {
        discount_cn: 6.6,
        discount_en: 34,
        discount_jp: 3.4,
      }
    }
    if (showDiscount) {
      return {
        discount_cn: showDiscount / 10,
        discount_en: 100 - showDiscount,
        discount_jp: (100 - showDiscount) / 10,
      };
    }
    return null;
  };
  const FTPopoverContent = () => {
    return <section
      className={clsx(
        styles.download_modal,
        isMobile && styles.modal_mobile,
      )}>
      {/* <div className={clsx(styles.download_modal_mask)}></div> */}
      <div
        className={clsx(styles.download_modal_content)}
        style={contentStyle}>
        <div className={clsx(styles.download_modal_title)}>
          <span>{t('download_popover_title')}</span>
          <span
            className={clsx(styles.download_modal_title_close)}
            onClick={handleClose}>
            <IconClose width={12} height={12} />
          </span>
        </div>
        <div className={clsx(styles.download_modal_item)}>
          <FTButton
            className={clsx(styles.download_modal_item_btn)}
            onClick={()=>handleDownloadPreview()}>
            <IconDownload />
            <span>{t('download_preview')}</span>
          </FTButton>
          <div className={clsx(styles.download_modal_item_desc)}>
            <span>{t('download_preview_desc')}</span> ·{' '}
            <span>
              {previewSize.width} × {previewSize.height} px
            </span>
          </div>
        </div>
        <div className={clsx(styles.download_modal_item)}>
          <FTButton
            type='primary'
            onClick={()=>handleDownloadHD()}
            className={clsx(
              styles.download_modal_item_btn,
              styles.primary,
            )}>
            <IconDownload />
            <span>{t('download_hd')}</span>
            {waterMarkText && (
              <div className={clsx(styles.download_modal_item_tips)}>
                {showVipIcon && (
                  <span className={styles.tips_icon}>
                    <IconWhitePro />
                  </span>
                )}
                <span>
                  {t(waterMarkText, getButtonDescDiscount())}
                </span>
              </div>
            )}
          </FTButton>
          <div className={clsx(styles.download_modal_item_desc)}>
            <span> {t('download_hd_desc')}</span> ·{' '}
            <span>
              {hdSize.width} × {hdSize.height} px
            </span>
          </div>
        </div>
      </div>
    </section>
  }

  return (
    <div className={clsx(styles.download, className)}>
      <Popover
        trigger={['click']}
        placement='bottomRight'
        overlayClassName={clsx(
          styles.download_modal_wrapper,
          isMobile && styles.mobile,
        )}
        visible={popoverVisible}
        ref={popoverRef}
        {...popoveProps}
        onVisibleChange={(visible) => {
          if (!visible) {
            setPopoverVisible(visible);
          }
        }}
        content={
          <FTPopoverContent />
        }>
        <FTButton
          type="primary"
          className={clsx(
            styles.main_download,
            popoverVisible && styles.disabled,
            btnClassName,
          )}
          onClick={handleShowModal}>
          <IconDownload />
          <span className={styles.main_download_text}>
            {t('goart_download')}
          </span>
        </FTButton>
      </Popover>
    </div>
  );
}
