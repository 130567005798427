import './index.scss';

import React, { useEffect } from 'react';

import { FTADManager } from 'src/utils';

interface IADWrapperProps {
  adId: string;
  type: string|string[];
  params?: any;
  callback?:(res)=>void
}
/**
 * 广告组件
 * @param props 
 * @returns 
 */
export default function ADWrapper(props: IADWrapperProps) {
  const { adId, type ,params} = props;
  useEffect(() => {
    if (!adId) return;
    if (!type) return;
    if(params){
      FTADManager.init(params);
      return;
    }
    FTADManager.init({
      eleId: adId,
      type: type,
    },(res)=>{
      props.callback?.(res);
    });
  }, []);
  return <div id={adId} data-id={adId} data-type={type}></div>;
}
