/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-09-09 14:23:10
 * @Description:
 */
// 根据用户 的vip资源使用情况，每天 提醒用户进行支付操作
import { FTGetLocalData, FTSaveLocalData } from "./index";
import dayjs from "dayjs";

class FreeTryPanelTip {
  constructor() {
    this.vipResourceTime = null;
    this.freeTrialPanel = null;
  }

  reset() {
    let currentTime = dayjs().valueOf();
    let refreshTime = FTGetLocalData("goart_free_trial_panel_refresh_time");
    if (refreshTime) {
      if (Number(refreshTime) <= currentTime) {
        this.setRefreshTime();
        this.resetfreeTrialPanel();
      }
    } else {
      this.setRefreshTime();
      this.resetfreeTrialPanel();
    }
    this.freeTrialPanel = FTGetLocalData("goart_free_trial_panel");
  }
  setRefreshTime() {
    let currentTime = dayjs();
    let time = dayjs()
      .date(currentTime.date() + 1)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .valueOf()
      .toString();
    FTSaveLocalData("goart_free_trial_panel_refresh_time", time);
  }

  resetfreeTrialPanel() {
    FTSaveLocalData("goart_free_trial_panel", false);
  }

  checkShowFreeTrialPanel() {
    if (!this.freeTrialPanel) {
      return false;
    }
    return true;
  }

  setShowFreeTrialPanel() {
    this.freeTrialPanel = true;
    FTSaveLocalData("goart_free_trial_panel", true);
  }
}

const FreeTryPanel = new FreeTryPanelTip();

export default FreeTryPanel;
