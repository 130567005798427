import type { APIClassifyItem, ExampleEffectItem } from "src/store/types";

/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-01 10:24:37
 * @Description:
 */
import axios from "axios";
import urls from "src/constant/url";

export const instance = axios.create({
  //创建axios实例，在这里可以设置请求的默认配置
  timeout: 60000, // 设置超时时间10s
  withCredentials: true,
  headers: {
    "x-app-id": "app-fotor-web"
  }
});

/** 添加请求拦截器 **/
instance.interceptors.request.use(
  (config: any) => {
    config.headers["Content-Type"] = config.headers["Content-Type"]
      ? config.headers["Content-Type"]
      : "application/x-www-form-urlencoded";
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
const axiosInstance = instance;
const loadSource = ({ url }: { url: string }) => {
  return axios({
    baseURL: process.env.PUBLIC_URL,
    url
  }).then((res: any) => res.data);
};

const loadEffectList = ({ url }: { url: string }) => {
  return axios({
    baseURL: process.env.PUBLIC_URL,
    url
  }).then((res: any) => res.data);
};

const FTGetSubscriptions = () => {
  return instance
    .get(`${urls.baseUrl}api/app/payment/subscriptions`, {
      headers: {
        "x-app-id": "app-fotor-web"
      }
    })
    .then((res) => {
      return res.data;
    });
};

const FTGetAdShowCountry = () => {
  let url = `${urls.baseUrl}a8s/api/v1/fotor/a8s/3rd/block?position=editorTool`;
  return instance
    .get(url, {
      headers: { "x-app-id": "app-fotor-web" } //设置header信息
    })
    .then((res) => {
      return res.data;
    });
};
const FTGetA8s = (data, language: string) => {
  let url = `${urls.baseUrl}a8s/api/v1/fotor/a8s`;
  return instance
    .get(url, {
      params: data,
      headers: { "x-app-id": "app-fotor-web", language, "x-data-version": "2" } //设置header信息
    })
    .then((res) => {
      return res.data;
    });
};
const FTGetLoop = (taskId: string) => {
  return instance
    .get(`${urls.baseUrl}api/create/goart-effects/${taskId}`, {
      headers: {
        "x-app-id": "app-fotor-web"
      }
    })
    .then((res) => {
      return res.data;
    });
};

const FTGetImageUrl = (mimeType: string = "jpeg") => {
  return instance
    .get(`${urls.baseUrl}api/create/sign?mimeType=${mimeType}`, {
      headers: {
        "x-app-id": "app-fotor-web"
      }
    })
    .then((res) => {
      return res.data;
    });
};

const FTGetAiArtImage = (params: {
  img: string;
  templateId: string;
  googleToken: string;
  googleAction: string;
}) => {
  let url = `${urls.baseUrl}api/create/goart-effects`;
  return instance
    .post(url, params, {
      headers: {
        "x-app-id": "app-fotor-web",
        "Content-Type": "application/json"
      } //设置header信息
    })
    .then((res) => {
      return res.data;
    });
};

const FTGetUserPanelType = () => {
  return instance
    .get(`${urls.baseUrl}pay/service/en/payment/pre-activity`)
    .then((res) => {
      return res.data;
    });
};

//用户行为分析

const FTUserProfile = (id: string) => {
  return instance
    .put(
      `${urls.baseUrl}api/user-profile/v1/tool`,
      { toolId: id },
      {
        headers: {
          "Content-Type": "application/json",
          "x-app-id": "app-fotor-web"
        } //设置header信息
      }
    )
    .then((res) => {
      return res.data;
    });
};

function getEffectList(
  language?: string
): FotorApiResultPromise<APIClassifyItem[]> {
  const isDev = process.env.REACT_APP_ENVIRONMENT !== "production";
  const url = isDev
    ? "https://test-goartwebapi.fotor.com/"
    : "https://goartwebapi.fotor.com/";
  return instance
    .get(`${url}api/effect/list/v2`, {
      params: {
        language
      },
      headers: {
        "Content-Type": "application/json"
      } //设置header信息
    })
    .then((res) => res.data);
}

/**
 * 获取示例效果
 */
function getExampleEffect(): FotorApiResultPromise<ExampleEffectItem[]> {
  return instance
    .get(`${urls.baseUrl}api/image/goart-effects/examples`, {
      headers: {
        "Content-Type": "application/json"
      } //设置header信息
    })
    .then((res) => res.data);
}

/**
 * 下载文件，返回进度
 * @param options
 * @param callback
 */
const downloadFileWithUrl = (
  options: { url: string },
  callback?: (e: any) => void
) => {
  let { url } = options;
  return axios({
    method: "get",
    url: url,
    responseType: "blob",
    // 下载超时时间设置长一些
    timeout: 60 * 1000 * 10,
    onDownloadProgress: (progressEvent) => {
      // console.log('===progressEvent:',progressEvent)
      callback && callback(progressEvent);
    }
  });
};

export {
  axiosInstance,
  loadSource,
  loadEffectList,
  FTGetSubscriptions,
  FTGetAdShowCountry,
  FTGetLoop,
  FTGetImageUrl,
  FTGetAiArtImage,
  FTGetUserPanelType,
  FTUserProfile,
  getExampleEffect,
  getEffectList,
  FTGetA8s,
  downloadFileWithUrl
};
