/*
 * @Author: Jiang ShaoDong
 * @Email: jiangshaodong@everimaging.com
 * @Date: 2020-08-18 15:25:42
 * @Description:
 */
// @ts-nocheck
/* eslint-disable */
import {getABTestGroup, utils} from "src/utils";
class FTSensorsAnalytic {
  name = "sa"
  constructor() {
    this.getSensorsSdk()

  }
  getSensorsSdk() {
    (function (para) {
      var p = para.sdk_url, n = para.name, w = window, d = document, s = 'script', x: any = null, y: any = null;
      if (typeof (w['sensorsDataAnalytic201505']) !== 'undefined') {
        return false;
      }
      w['sensorsDataAnalytic201505'] = n;
      w[n] = w[n] || function (a) { return function () { (w[n]._q = w[n]._q || []).push([a, arguments]); } };
      var ifs = ['track', 'quick', 'register', 'registerPage', 'registerOnce', 'trackSignup', 'trackAbtest', 'setProfile', 'setOnceProfile', 'appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify', 'login', 'logout', 'trackLink', 'clearAllRegister', 'getAppStatus'];
      for (var i = 0; i < ifs.length; i++) {
        w[n][ifs[i]] = w[n].call(null, ifs[i]);
      }

      if (!w[n]._t) {
        x = d.createElement(s), y = d.getElementsByTagName(s)[0];
        x.async = 1;
        x.src = p;
        x.setAttribute('charset', 'UTF-8');
        w[n].para = para;
        y.parentNode.insertBefore(x, y);
      }
    })({
      sdk_url: 'https://pub-static.fotor.com/static/web/lib/sensors/sensorsdata.min.js',
      name: 'sensors',
      server_url: process.env.REACT_APP_SA_DATA_URL,
      // 2024-5-15 去掉全埋点
      heatmap: { scroll_notice_map: 'not_collect', clickmap: "not_collect" },
      // 单页面，是否在路由切换时发送pageView
      is_track_single_page: false,
      // 是否打印日志
      show_log: process.env.REACT_APP_ENVIRONMENT !== 'production',
    });

    // 设置公共属性
    let publicProperty = { is_login: false, vip_status: 0, platform_type: "web" }
    try {
      const localUserInfo = window.localStorage.getItem("fotor_common_user_Info");
      if (localUserInfo) {
        const userInfo = JSON.parse(localUserInfo)
        publicProperty = { ...publicProperty, is_login: true, vip_status: userInfo.vipStatus || userInfo.vipStatus == 0 ? userInfo.vipStatus : 0 }
      }
    } catch (error) {

    }
    const ga = utils.getOldCookie('_ga')
    // @ts-ignore
    window.sensors && window.sensors.registerPage({ ga });
    // @ts-ignore
    window.sensors && window.sensors.registerPage(publicProperty)
    // @ts-ignore
    window.sensors && window.sensors.quick('autoTrack', { ga });
    // @ts-ignore

    //删除A／B测试　在公共组件注册
    const checkSensorsAbTest = () => {
      if (window.sensors && window.sensors.use) {
        this.initSensorsAbTest();
        cancelAnimationFrame(checkFrame);
      } else {
        checkFrame = requestAnimationFrame(checkSensorsAbTest);
      }
    };
    // // 开始轮询
    let checkFrame = requestAnimationFrame(checkSensorsAbTest);
  }

  //初始化abTest
   initSensorsAbTest = (callback, parm) => {
    const abTestServer =
      process.env.NODE_ENV === "production"
        ? "https://abtest-aws-us-east-01.saas.sensorsdata.com/api/v2/abtest/online/results?project-key=C21B82C8696B2F75F7CC6D674C3DF39D1F32BFE0"
        : "https://abtest-aws-us-east-01.saas.sensorsdata.com/api/v2/abtest/online/results?project-key=8D686DF5D362BA785A0EF5318E195E90770DA34E";
    if (window.sensors && !window.sensors.ABTest) {
      const abtestScript = document.createElement("script");
      abtestScript.type = "text/javascript";
      abtestScript.src =
        "https://pub-static.fotor.com/static/web/lib/sensors/abtest.min.js";
      document.head.appendChild(abtestScript);
      abtestScript.onload = () => {
        if (!window.sensors.ABTest) {
          const abtest =
            window.sensors &&
            window.sensors.use &&
            window.sensors.use("SensorsABTest", {
              url: abTestServer
            });
          window.sensors.ABTest = abtest;
          if (callback) {
            if (parm) {
              callback(parm);
            } else {
              callback();
            }
          }
        }
      };
      abtestScript.onerror = () => {
        if (callback) {
          if (parm) {
            callback(parm);
          } else {
            callback();
          }
        }
      };
    } else {
      if (callback) {
        if (parm) {
          callback(parm);
        } else {
          callback();
        }
      }
    }
  };
  addPV(info: any) {
    // let { url, userId, token, isVip, adblock, specialContent } = info;
    // // @ts-ignore
    // window.dataLayer.push({
    //   event: "trackPageView",
    //   url,
    //   locale: "zh-cn",
    //   userId: userId || "",
    //   token: token || "",
    //   isVip,
    //   visitTimes: "",
    //   visitDuration: "",
    //   usingDuration: "",
    //   appVersion: "1.0.0",
    //   adblock,
    //   specialContent,
    //   product: "h5-app",
    //   host: document.location.host,
    //   apiKey: "fotor"
    // });
  }
  addEvent(info: any) {
    let {
      action,
      label,
      ...otherProps
    } = info;
    if(action){
      action = action.replace(/#/g,'$')
    }
    // @ts-ignore
    window.sensors && window.sensors.track(
      action,
      label
    )
  }
}
export default FTSensorsAnalytic;
