/*
 * @Author: Jiang ShaoDong
 * @Email: jiangshaodong@everimaging.com
 * @Date: 2020-08-18 13:29:57
 * @Description:
 */

import FTGoogleAnalytic from "./FTGoogleAnalytic";
import FTSensorsAnalytic from "./FTSensorsAnalytic";
import cuid from "cuid";

class FTAnalytic {
  appId: string;
  ver = "v1";
  // 下载弹窗唤起的一次流程id
  dialogSessionId: any;
  private _sensorAnalytic: FTSensorsAnalytic | undefined;
  private _googleAnalytic: FTGoogleAnalytic | undefined;
  constructor() {
    this.appId = cuid();
    // this.saObj = FTSensorsAnalytic;
    if (navigator.userAgent !== "ReactSnap") {
      this._sensorAnalytic = new FTSensorsAnalytic();
      this._googleAnalytic = new FTGoogleAnalytic();
    }
  }

  getType(type: string | undefined) {
    let away: any[] = [];
    switch (type) {
      case "sa": {
        away = [this._sensorAnalytic];
        break;
      }
      case "ga": {
        away = [this._googleAnalytic];
        break;
      }
      default: {
        away = [this._sensorAnalytic, this._googleAnalytic];
      }
    }
    return away;
  }

  /**
   *
   * @param info{object}
   * {
   * type: pv or ev(pageView或者event事件),
   * action:ev第二个参数,
   * label:ev第三个参数
   * }
   */
  addAnalytic(info: {
    type: "ev" | "pv";
    action: string;
    label?: any;
    analyticType?: "ga" | "ba" | "sa" | "ga|sa";
    value?: number;
  }) {
    // console.log("Analytic:", info);
    info.label!.version = process.env.REACT_APP_VERSION;
    info.label!.project_type = "goart";
    let aways = this.getType(info.analyticType);
    aways.forEach((item) => {
      try {
        if (info.type === "pv") {
          item && item.addPV(info);
        } else {
          item && item.addEvent(info);
        }
      } catch (e) {
        console.log("analytics error");
      }
    });
  }
}

export default new FTAnalytic();
