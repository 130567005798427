import React, { useEffect } from "react";

import FTLang from "src/utils/FTLang";
import clsx from "clsx";
import styles from "./FTFullDragUpload.module.scss";
import { useIsMobile } from "src/hooks";
import { withPublicUrl } from "src/utils";

type propsType = {
  onDropFiles: (files: File[]) => void;
};
const DROPIMAGE_URL = withPublicUrl("img", "bgRemover", "drop-image.png");

const FTFullDragUpload = (props: propsType) => {
  const [show, setShow] = React.useState(false);
  const current = React.useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    // 支持ctrl+v粘贴图片
    const fn = (event: ClipboardEvent) => {
      const loginRoot = document.getElementById("rootLogin");
      if (loginRoot && loginRoot.children.length > 0) return;
      const payRoot = document.getElementById("rootPayment");
      if (payRoot && payRoot.children.length > 0) return;
      const clipboardData =
        event.clipboardData ||
        ((event as any).originalEvent as any).clipboardData;
      var items = [...(clipboardData?.items ?? [])];

      if (items.length > 0) {
        const everyIsFile = items.every((item) => item.kind === "file");
        if (everyIsFile) {
          const blobs: File[] = [];
          for (var i = 0; i < items.length; i++) {
            if (items[i].kind === "file") {
              var blob = items[i].getAsFile();
              if (blob.size > 0) {
                blobs.push(blob);
              }
            }
          }
          if (blobs.length > 0) {
            props.onDropFiles?.(blobs);
          }
        }
      }
    };
    document.addEventListener("paste", fn);
    return () => {
      document.removeEventListener("paste", fn);
    };
  }, []);

  const checkHasFile = (e) => {
    return e.dataTransfer.types.find((item) => item === "Files");
    // return e?.dataTransfer?.files?.length > 0;
  };

  const onDragOver = (e) => {
    const loginRoot = document.getElementById("rootLogin");
    if (loginRoot && loginRoot.children.length > 0) return;
    const payRoot = document.getElementById("rootPayment");
    if (payRoot && payRoot.children.length > 0) return;
    if (checkHasFile(e)) {
      setShow(true);
    }
  };

  const onDragLeave = (res) => {
    if (res.target === current.current) {
      setShow(false);
    }
  };

  const handleonDragEnd = (e: any) => {
    // 拖入图片的阻止 浏览器的默认事件  火狐阻止拖入文件新开窗口
    e.stopPropagation();
    e.preventDefault();
    if (checkHasFile(e)) {
      const files = Array.from(e.dataTransfer.files);
      handleAddImage(files);
      setShow(false);
    }
  };
  const handleAddImage = (file: any = null) => {
    window.scrollTo(0, 0);
    props.onDropFiles?.(file);
  };

  useEffect(() => {
    if (window && document) {
      document.body.addEventListener("dragover", onDragOver);
      window.addEventListener("dragleave", onDragLeave);
    }
    return () => {
      if (window && document) {
        document.body.removeEventListener("dragover", onDragOver);
        window.removeEventListener("dragleave", onDragLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile) return;
    if (show) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "hidden";
    }
  }, [show, isMobile]);

  return (
    <div
      className={clsx(styles.drag_mark_layer, show && styles.show)}
      ref={current}
      onDrop={handleonDragEnd}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={DROPIMAGE_URL} alt='' />
          <p>{FTLang._("drop_img_desc")}</p>
        </div>
        <div className={styles.angle}></div>
        <div className={styles.angle}></div>
        <div className={styles.angle}></div>
        <div className={styles.angle}></div>
      </div>
    </div>
  );
};

export default FTFullDragUpload;
