/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 20:05:55
 * @Description:
 */

import { EnumsShowStatus, imageProcesserSize, urls } from "src/constant";
import {
  FTLang,
  canvasToBlob,
  cloneCanvasWithMaxSide,
  getCurrentTime,
  imageLoader,
  utils
} from "src/utils";
import { configure, observable } from "mobx";

import { FTCanvasStoreType } from "./types";
import FTImageStore from "./FTImageStore";
import FTUserStore from "./FTUserStore";
import ImageProcessor from "src/ImageProcessor";
import { actionDownloadResult } from "src/analyze/actions";
import { message } from "antd";
import { saveAs } from "file-saver";

// mobx 配置
configure({
  enforceActions: "never" // 允许直接修改状态
});

const FTCanvasStore = observable.object<FTCanvasStoreType>({
  canvas: null,
  ctx: null,
  waterMark: null,
  hoverWatermark: null,
  waterMarkHover: false,
  loading: false, // 图片加载状态
  downloadLoading: false,
  showDownload: false,
  isMobile: false,
  downloadImage: null,
  compareCanvas: null,
  hasCompare: false,
  waterInfo: { width: 0, height: 0, scale: 1, x: 0, y: 0 },
  downloadModalVisible: false,
  setDownLoadVisible(value: boolean) {
    this.downloadModalVisible = value;
  },
  downloadSuccess: false,
  setDownloadSuccess(value) {
    this.downloadSuccess = value;
  },
  async draw() {
    if (!this.canvas || !this.ctx) return;
    let { imageElement, effectElement, currentIntensity } = FTImageStore;
    let { width, height } = this.canvas as HTMLCanvasElement;
    // if(currentEffect && currentEffect.isgrey && greyImageElement) {
    //   this.ctx.drawImage(greyImageElement,0 ,0 , greyImageElement?.width, greyImageElement?.height, 0, 0, width, height)
    // } else {
    //   this.ctx.drawImage(imageElement,0 ,0 , imageElement?.width, imageElement?.height, 0, 0, width, height)
    // }
    this.ctx.drawImage(
      imageElement,
      0,
      0,
      imageElement?.width,
      imageElement?.height,
      0,
      0,
      width,
      height
    );
    this.ctx.save();
    this.ctx.globalAlpha = currentIntensity / 100;
    effectElement && this.ctx.drawImage(effectElement, 0, 0, width, height);
    this.ctx.restore();
    await this.addWaterMark(this.ctx);
    if (this.compareCanvas) {
      let ctx = this.compareCanvas.getContext("2d");
      ctx.drawImage(
        FTImageStore.imageElement,
        0,
        0,
        imageElement?.width,
        imageElement?.height,
        0,
        0,
        this.compareCanvas.width,
        this.compareCanvas.height
      );
    }
  },
  changeLoading(value) {
    this.loading = value;
  },
  init() {
    utils
      .getImageFromUrl(urls.assetsUrl + "/img/fotor_watermark_web_4x.png")
      .then((res) => {
        this.waterMark = res.image;
      });
    utils
      .getImageFromUrl(urls.assetsUrl + "/img/goart_watermark_webHover.png")
      .then((res) => {
        this.hoverWatermark = res.image;
      });
  },
  setShowDownload(value: boolean) {
    this.showDownload = value;
  },
  async addWaterMark(ctx, force = false) {
    if (
      (FTImageStore.currentEffect &&
        FTImageStore.currentEffect.name !== "Original" &&
        !FTUserStore.isVip) ||
      force
    ) {
      let { width, height } = ctx.canvas;
      if (!this.waterMark) {
        this.waterMark = await utils
          .getImageFromUrl(urls.assetsUrl + "/img/fotor_watermark_web_4x.png")
          .then((res) => res.image);
      }
      if (!this.hoverWatermark) {
        this.hoverWatermark = await utils
          .getImageFromUrl(urls.assetsUrl + "/img/goart_watermark_webHover.png")
          .then((res) => res.image);
      }

      // let scale = 1
      // if(width > height) {
      //   scale = this.waterMark!.width / (width / 2)
      // } else {
      //   scale = this.waterMark!.height / (height / 2)
      // }
      let size = Math.min(width, height);
      let scale = Math.max(
        this.waterMark!.width / size,
        this.waterMark!.height / size
      );
      let offsetH = 0,
        offsetW = 0,
        x = 0,
        y = 0;
      if (width < height) {
        offsetW = (this.waterMark!.width / scale) * 0.25;
        offsetH = (this.waterMark!.height / scale) * 0.25;
        x = width * 0.03;
        y = height - x - offsetH;
      } else {
        offsetW = (this.waterMark!.width / scale) * 0.25;
        offsetH = (this.waterMark!.height / scale) * 0.25;
        y = height * 0.94 - offsetH;
        x = height - y - offsetH;
      }
      this.waterInfo = { width: offsetW, height: offsetH, scale, x, y };
      ctx.drawImage(
        this.waterMarkHover ? this.hoverWatermark : this.waterMark,
        0,
        0,
        this.waterMark?.width,
        this.waterMark?.height,
        x,
        y,
        offsetW,
        offsetH
      );
    }
  },
  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.draw();
    this.loading = false;
  },
  downloadPreview() {
    this.downloadLoading = true;
    FTImageStore.progress.upload = 0;
    FTImageStore.progress.download = 0;
    FTImageStore.progress.task = 0;
    this.setDownLoadVisible(true);
    let { resultSize, acturalSize } = FTImageStore;
    const width = resultSize.width || acturalSize.width;
    const height = resultSize.height || acturalSize.height;
    let canvas = document.createElement("canvas");
    let smallScale = utils.aspectFitScale(
      width,
      height,
      imageProcesserSize.IMAGE_SIDE_VALUE
    );
    let smallWidth = Math.round(width * smallScale);
    let smallHeight = Math.round(height * smallScale);
    this.setDownloadSuccess(false);
    this.download(canvas, smallWidth, smallHeight, false)
      .then((res) => {
        actionDownloadResult("download_preview", true);
        this.downloadLoading = false;
        const file = new File([res.blob], FTImageStore.currentImage.name, {
          type: FTImageStore.currentImage.type
        });
        this.showDownloadSuccessResult(URL.createObjectURL(file));
      })
      .catch((err) => {
        actionDownloadResult("download_preview", false, err);
        console.error(err);
        message.error(FTLang._("goart_download_error"));
        this.downloadLoading = false;
        this.setDownLoadVisible(false);
      });
  },
  async downloadFullSize() {
    this.downloadLoading = true;
    FTImageStore.progress.upload = 0;
    FTImageStore.progress.download = 0;
    FTImageStore.progress.task = 0;

    this.setDownLoadVisible(true);
    let { resultSize, acturalSize } = FTImageStore;
    let canvas = document.createElement("canvas");
    const width = resultSize.width || acturalSize.width;
    const height = resultSize.height || acturalSize.height;
    let largerScale = utils.aspectFitScale(
      width,
      height,
      Number(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID)
    );
    let largerWidth = Math.round(width * largerScale);
    let largerHeight = Math.round(height * largerScale);
    this.setDownloadSuccess(false);
    const { usedEffect, currentEffect } = FTImageStore;
    const img = usedEffect[currentEffect?.id];
    if (img && currentEffect?.aigc) {
      await this.preDownload(canvas, largerWidth, largerHeight);
      const newCanvas = this.afterHDDownload(canvas);
      FTImageStore.progress.upload = 100;
      FTImageStore.progress.task = 100;
      FTImageStore.progress.download = 10;
      canvasToBlob(newCanvas, FTImageStore.currentImage.type, 1)
        .then((blob) => {
          FTImageStore.progress.download = 100;
          const file = new File([blob], FTImageStore.currentImage.name, {
            type: FTImageStore.currentImage.type
          });

          this.downloadLoading = false;
          this.setDownloadSuccess(false);
          let fullName = FTImageStore.currentImage.name;
          let parts = fullName.split(".");
          let fileExtension = parts.pop();
          let fileName = parts.join(".");
          const fileNameNew = `${fileName}-fotor-ai-art-effects-${getCurrentTime("date1")}.${fileExtension}`;
          saveAs(file, fileNameNew);
          actionDownloadResult("download_fullsize", true);
          this.showDownloadSuccessResult(URL.createObjectURL(file));
        })
        .catch((error) => {
          actionDownloadResult("download_fullsize", false,error);
          console.error(error);
          message.error(FTLang._("goart_download_error"));
          this.downloadLoading = false;
          this.setDownLoadVisible(false);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
      return;
    }
    if (!currentEffect) {
      // 没有选择效果
      await this.preDownload(canvas, largerWidth, largerHeight);
      const newCanvas = this.afterHDDownload(canvas);
      FTImageStore.progress.upload = 100;
      FTImageStore.progress.task = 100;
      FTImageStore.progress.download = 10;
      canvasToBlob(newCanvas, FTImageStore.currentImage.type, 1)
        .then((blob) => {
          FTImageStore.progress.download = 100;
          const file = new File([blob], FTImageStore.currentImage.name, {
            type: FTImageStore.currentImage.type
          });

          saveAs(file);
          actionDownloadResult("download_fullsize", true);
          this.downloadLoading = false;
          this.showDownloadSuccessResult(URL.createObjectURL(file));
        })
        .catch((error) => {
          actionDownloadResult("download_fullsize", false,error);
          console.error(error);
          message.error(FTLang._("goart_download_error"));
          this.downloadLoading = false;
          this.setDownLoadVisible(false);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
      return;
    }
    this.download(canvas, largerWidth, largerHeight, true)
      .then((res) => {
        actionDownloadResult("download_fullsize", true);
        this.downloadLoading = false;
        this.showDownloadSuccessResult(URL.createObjectURL(res.blob));
      })
      .catch((err) => {
        actionDownloadResult("download_fullsize", false,err);
        console.error(err);
        message.error(FTLang._("goart_download_error"));
        this.downloadLoading = false;
        this.setDownLoadVisible(false);
      })
      .finally(() => {
        this.downloadLoading = false;
      });
  },
  async preDownload(canvas: HTMLCanvasElement, width: number, height: number) {
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    let blobUrl = "";
    let {
      imageElement,
      effectElement,
      currentIntensity,
      currentImage,
      resultSize
    } = FTImageStore;
    if (resultSize.width > 0) {
      ctx!.drawImage(
        imageElement!,
        0,
        0,
        imageElement!.width,
        imageElement!.height,
        0,
        0,
        width,
        height
      );
    } else {
      blobUrl = URL.createObjectURL(currentImage);
      const img = await imageLoader(blobUrl);
      ctx!.drawImage(img!, 0, 0, img!.width, img!.height, 0, 0, width, height);
    }
    // }
    ctx!.save();
    ctx!.globalAlpha = currentIntensity / 100;
    effectElement && ctx!.drawImage(effectElement, 0, 0, width, height);
    ctx!.restore();
    if (blobUrl) {
      URL.revokeObjectURL(blobUrl);
    }
  },
  afterHDDownload(canvas: HTMLCanvasElement) {
    let { resultSize } = FTImageStore;
    const { width: resultWidth, height: resultHeight } = resultSize;

    const newCanvas = document.createElement("canvas");
    if (resultWidth > 0 && resultHeight > 0) {
      const resultSclae =
        Math.max(resultWidth, resultHeight) /
        Number(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID);
      newCanvas.width = Math.round(resultWidth / resultSclae);
      newCanvas.height = Math.round(resultHeight / resultSclae);
    } else {
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;
    }

    const context = newCanvas.getContext("2d");
    context?.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newCanvas.width,
      newCanvas.height
    );
    return newCanvas;
  },
  showDownloadSuccessResult(imgUrl: string) {
    if (utils.checkIsMobile()) {
      window.mobileCommon &&
        window.mobileCommon.DownloadResultPopupInstance().show({
          bgColor: "transparent",
          projectType: "goart",
          imgUrl
        });
    } else {
      setTimeout(() => {
        this.setDownloadSuccess(true);
      }, 500);
    }
  },
  async download(
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    needSuper: boolean = false
  ) {
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    let {
      imageElement,
      effectElement,
      currentIntensity,
      goartOriginImage,
      currentEffect
    } = FTImageStore;
    ctx!.drawImage(
      imageElement!,
      0,
      0,
      imageElement!.width,
      imageElement!.height,
      0,
      0,
      width,
      height
    );
    ctx!.save();
    ctx!.globalAlpha = currentIntensity / 100;
    effectElement && ctx!.drawImage(effectElement, 0, 0, width, height);
    ctx!.restore();
    if (needSuper) {
      //看看是否保存过当前返回普清图片的临时地址，缓存
      if (FTImageStore.artHDUploadImageUrl?.[currentEffect.id] === undefined) {
        let result: File = await new Promise((resolve) => {
          // const newImage = cloneCanvasWithMaxSide(
          //   canvas,
          //   parseInt(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID, 10)
          // );
          // newImage.toBlob((blob: Blob | null) => {
          //   let files = new File(
          //     [blob as Blob],
          //     FTImageStore.currentImage.name
          //   );
          //   resolve(files);
          // }, "image/jpeg");
          goartOriginImage!.toBlob((blob: Blob | null) => {
            let files = new File(
              [blob as Blob],
              FTImageStore.currentImage.name
            );
            resolve(files);
          }, "image/jpeg");
        });
        const key = await FTImageStore.uploadFile(result,(progress)=>{
          FTImageStore.progress.upload = progress;
        });
        if (FTImageStore.artHDUploadImageUrl === null) {
          FTImageStore.artHDUploadImageUrl = {};
        }
        FTImageStore.artHDUploadImageUrl[currentEffect.id] = key;
      }

      // let image: HTMLImageElement = await ImageProcessor.processByUrl(
      //   FTImageStore.artHDUploadImageUrl[currentEffect.id],
      //   FTImageStore.currentEffect.id
      // );
      let image: HTMLImageElement = await ImageProcessor.processByUrl(
        FTImageStore.artHDUploadImageUrl[currentEffect.id],
        imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID
      );
      if (!image) {
        // TODO: 下载超分失败容错
        return Promise.reject();
      }
      let { acturalSize } = FTImageStore;
      let scale =
        Math.max(acturalSize.width, acturalSize.height) /
        Number(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID);
      canvas.width = Math.round(acturalSize.width / scale);
      canvas.height = Math.round(acturalSize.height / scale); // 这里可能导致画布被清空，需要重新画一次
      ctx!.drawImage(
        imageElement!,
        0,
        0,
        imageElement!.width,
        imageElement!.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      ctx!.save();
      ctx!.globalAlpha = currentIntensity / 100;
      ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx!.restore();
    } else {
      await this.addWaterMark(ctx!, true);
    }
    return new Promise<{ result: boolean; blob: Blob }>((resolve, reject) => {
      try {
        // 下载
        let decode = canvas.toDataURL(FTImageStore.currentImage.type, 1);
        let blob = utils.dataURItoBlob(decode);
        let fullName = FTImageStore.currentImage.name;
        let parts = fullName.split(".");
        let fileExtension = parts.pop();
        let fileName = parts.join(".");
        const fileNameNew = `${fileName}-fotor-ai-art-effects-${getCurrentTime("date1")}.${fileExtension}`;
        saveAs(blob, fileNameNew); // 高清下载使用原文件名
        resolve({ result: true, blob });
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  },

  setIsMobile(value) {
    this.isMobile = value;
    message.config({
      top: value ? 30 : 60
    });
  },
  async getDownloadImage() {
    let { currentSize, imageElement, effectElement, currentIntensity } =
      FTImageStore;
    let canvas = document.createElement("canvas");
    let smallScale = utils.aspectFitScale(
      currentSize.width,
      currentSize.height,
      imageProcesserSize.IMAGE_SIDE_VALUE
    );
    let smallWidth = Math.round(currentSize.width * smallScale);
    let smallHeight = Math.round(currentSize.height * smallScale);
    canvas.width = smallWidth;
    canvas.height = smallHeight;
    let ctx = canvas.getContext("2d");
    ctx!.drawImage(
      imageElement!,
      0,
      0,
      imageElement!.width,
      imageElement!.height,
      0,
      0,
      smallWidth,
      smallHeight
    );
    ctx!.save();
    ctx!.globalAlpha = currentIntensity / 100;
    effectElement &&
      ctx!.drawImage(effectElement, 0, 0, smallWidth, smallHeight);
    ctx!.restore();
    await this.addWaterMark(ctx!);
    return new Promise((resolve, reject) => {
      try {
        let decode = canvas.toDataURL(FTImageStore.currentImage.type, 1);
        let blob = utils.dataURItoBlob(decode);
        resolve(window.URL.createObjectURL(blob));
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  },
  compare(bool) {
    this.hasCompare = bool;
    if (bool) {
      // FTCanvasStore.canvas!.style.display = "none";
      // FTCanvasStore.compareCanvas!.style.display = "block";
      FTImageStore.showStatus = EnumsShowStatus.compare;
    } else {
      // FTCanvasStore.canvas!.style.display = "block";
      // FTCanvasStore.compareCanvas!.style.display = "none";
      FTImageStore.showStatus = EnumsShowStatus.result;
    }
  },
  get hasShowWater() {
    return (
      FTImageStore.currentEffect &&
      FTImageStore.currentEffect.name !== "Original" &&
      !FTUserStore.isVip
    );
  }
});

export default FTCanvasStore;
