import React, { useEffect } from "react";

import { debounce } from "src/utils/debounce";

export default function useScroll(
  scrollRef: React.RefObject<HTMLDivElement>,
  padding = 0,
  dependences: any[] = []
) {
  const [prevDisabled, setPreDisabled] = React.useState(true);
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const [clientWidth, setClientWidth] = React.useState(0);
  const childWidthRef = React.useRef<number[]>([]);
  const offsetLeftRef = React.useRef<number[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const pageRef = React.useRef<
    {
      start: number;
      end: number;
      width: number;
    }[]
  >([]);

  useEffect(() => {
    const onResize = () => {
      if (scrollRef.current) {
        let clientWidth = scrollRef.current.clientWidth;
        setScrollLeft(scrollRef.current.scrollLeft);
        setScrollWidth(scrollRef.current.scrollWidth);
        setClientWidth(clientWidth);
        scrollRef.current.childNodes.forEach((child: any, index: number) => {
          const childWidth = child.offsetWidth;
          const offsetLeft = child.offsetLeft;
          const computedStyle = window.getComputedStyle(child);
          const marginLeft = parseInt(
            computedStyle.marginLeft.replace("px", "")
          );
          const marginRight = parseInt(
            computedStyle.marginRight.replace("px", "")
          );
          childWidthRef.current[index] = childWidth + marginLeft + marginRight;
          offsetLeftRef.current[index] = offsetLeft;
        });
        // 对宽度进行分页，要求每页的宽度不超过clientWidth
        let totalWidth = 0;
        let startIndex = 0;
        pageRef.current = [];
        childWidthRef.current.forEach((width, index) => {
          if (index === childWidthRef.current.length - 1 && totalWidth > 0) {
            if (totalWidth + width + padding > clientWidth) {
              pageRef.current.push({
                start: startIndex,
                end: index,
                width: totalWidth
              });
              pageRef.current.push({
                start: index,
                end: index,
                width: width
              });
            } else {
              pageRef.current.push({
                start: startIndex,
                end: index,
                width: totalWidth + width
              });
            }
          } else if (totalWidth + width + padding > clientWidth) {
            pageRef.current.push({
              start: startIndex,
              end: index,
              width: totalWidth
            });
            totalWidth = 0;
            startIndex = index + 1;
          } else {
            totalWidth += width;
          }
        });
      }
    };
    const onScroll = debounce(() => {
      if (!scrollRef.current) {
        return;
      }
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setScrollLeft(scrollLeft);
      setScrollWidth(scrollWidth);
      setClientWidth(clientWidth);
    }, 100);
    onResize();
    scrollRef.current?.addEventListener("resize", onResize);
    scrollRef.current?.addEventListener("scroll", onScroll);
    return () => {
      scrollRef.current?.removeEventListener("resize", onResize);
      scrollRef.current?.removeEventListener("scroll", onScroll);
    };
  }, dependences);

  useEffect(() => {
    if (scrollWidth > 0) {
      setPreDisabled(scrollLeft === 0);
      setNextDisabled(scrollLeft + clientWidth + 1 >= scrollWidth);
    }
  }, [clientWidth, scrollLeft, scrollWidth]);

  const handlePrev = () => {
    if (scrollRef.current) {
      const index = activeIndex - 1;
      if (index < 0) {
        scrollRef.current.scrollTo({
          left: 0,
          behavior: "smooth"
        });
        return;
      }
      setActiveIndex(index);
      const currentPage = pageRef.current[index];
      const prevWidth = childWidthRef.current
        .slice(0, Math.max(currentPage.start - 1, 0))
        .reduce((a, b) => a + b, 0);
      const t = scrollLeft > prevWidth ? padding : -padding; // 向左滚动时，需要+padding
      scrollRef.current.scrollTo({
        left: Math.max(prevWidth, 0) - padding,
        behavior: "smooth"
      });
    }
  };

  const handleNext = () => {
    const clilentWidth = scrollRef.current?.clientWidth || clientWidth;
    if (scrollRef.current) {
      const index = activeIndex + 1;
      if (index >= pageRef.current.length) {
        scrollRef.current.scrollTo({
          left: scrollWidth + clilentWidth,
          behavior: "smooth"
        });
        setScrollLeft(scrollWidth + clilentWidth)
        return;

      }
      setActiveIndex(index);
      const currentPage = pageRef.current[index];
      const prevWidth = childWidthRef.current
        .slice(0, Math.max(currentPage.start - 1, 0))
        .reduce((a, b) => a + b, 0);
      const t = scrollLeft > prevWidth ? padding : -padding; // 向左滚动时，需要+padding
      scrollRef.current.scrollTo({
        left: Math.max(prevWidth, 0) + t,
        behavior: "smooth"
      });
      setScrollLeft(Math.max(prevWidth, 0) + t)

    }
  };

  const handleActive = (index: number) => {
    const clilentWidth = scrollRef.current?.clientWidth || clientWidth;
    if (offsetLeftRef.current.length !== 0) {
      const offsetLeft = offsetLeftRef.current[index];
      if (offsetLeft < scrollLeft) {
        scrollRef.current?.scrollTo({
          left: offsetLeft - padding,
          behavior: "smooth"
        });
        setScrollLeft(offsetLeft - padding)
      }
      if (offsetLeft > scrollLeft + clilentWidth) {
        scrollRef.current?.scrollTo({
          left:
            offsetLeft - clilentWidth + childWidthRef.current[index] + padding,
          behavior: "smooth"
        });
        setScrollLeft(offsetLeft - clilentWidth + childWidthRef.current[index] + padding)
      }
    }

    const pageIndex = pageRef.current.findIndex(
      (page) => index >= page.start && page.end >= index
    );
    if (pageIndex >= 0) {
      const page = pageRef.current[pageIndex];
      const preWidth = childWidthRef.current
        .slice(0, index)
        .reduce((a, b) => a + b, 0);
      const currentWidth = childWidthRef.current[index];
      const t = scrollLeft > preWidth ? padding : -padding;
      const start = scrollLeft;
      const end = scrollLeft + clilentWidth;
      if (preWidth < start + padding) {
        scrollRef.current?.scrollTo({
          left: Math.max(preWidth, 0) - padding,
          behavior: "smooth"
        });
        setScrollLeft(Math.max(preWidth, 0) - padding)

      } else if (preWidth + currentWidth > end - padding) {
        scrollRef.current?.scrollTo({
          left: Math.max(preWidth - clilentWidth + currentWidth, 0) - t,
          behavior: "smooth"
        });
        setScrollLeft(Math.max(preWidth - clilentWidth + currentWidth, 0) - t)

      }
    }
  };

  return {
    prevDisabled,
    nextDisabled,
    handlePrev,
    handleNext,
    handleActive
  };
}
