/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 13:58:34
 * @Description:
 */
import { FTCanvasStore, FTImageStore, FTUserStore } from 'src/store';
import React, { useEffect } from 'react';

import FTNoImageMobile from "src/components/FTNoImageMobile/FTNoImageMobile";
import FTNoImageSkedon from 'src/components/FTNoImageMobile/FTNoImageSkedon';
import NoImageMask from 'src/pages/Content/NoImageMask';
import { autorun } from 'mobx';
import loadable from '@loadable/component';

// import FTCanvas from "./FTCanvas";

const FTCanvas = loadable(
  () =>
    import(
      /* webpackChunkName: "FTCanvas" */
      './FTCanvas'
    ),
);

// const FTNoImageMobile = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "FTNoImageMobile" */
//       'src/components/FTNoImageMobile/FTNoImageMobile'
//     ),
//   {
//     fallback: <FTNoImageSkedon />,
//   },
// );

interface IState {
  isLogin: boolean;
  showCanvas: boolean;
  isMobile: boolean;
}

interface IProps {
  className: string;
  showAd: boolean;
}
function FTCenter(props: IProps) {
  const [isLogin, setIsLogin] = React.useState(false);
  const [showCanvas, setShowCanvas] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  useEffect(() => {
    const dispose = autorun(() => {
      autorun(() => {
        let { userInfo } = FTUserStore;
        let { currentImage } = FTImageStore;
        let { isMobile } = FTCanvasStore;
        setIsLogin(!!userInfo);
        setShowCanvas(!!currentImage);
        setIsMobile(isMobile);
      });
    });
    FTCanvas.preload();
    return dispose;
  }, []);

  const { className, showAd } = props;
  return (
    <div className={className}>
      {showCanvas ? (
        <FTCanvas showAd={showAd} />
      ) : isMobile ? (
        <FTNoImageMobile />
      ) : (
        <NoImageMask />
      )}
    </div>
  );
}

export default FTCenter;
