/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 13:58:17
 * @Description:
 */

import "./FTNoImageMobile.scss";

import { useLanguage, useQueryRoute } from "src/hooks";

import FTImageStore from "src/store/FTImageStore";
import FTLang from "src/utils/FTLang";
import FTNoImageLinkToAIGC from "./FTNoImageLinkToAIGC/FTNoImageLinkToAIGC";
import { IconPlus } from "src/assets";
import React from "react";
import { actionFeatureClickUp } from "src/analyze/actions";

export default function FTNoImageMobile() {
  const {t} = useLanguage();
  useQueryRoute();
  const uploadImage = async () => {
    actionFeatureClickUp("get_started")
    FTImageStore.uploadImage("upload_image");
  };
  return (
    <div className='noimage_mobile'>
      <FTNoImageLinkToAIGC />
      <div className='noimage_mobile_center' onClick={uploadImage}>
        <div>
          <IconPlus className='noimage_mobile_icon' />
          <div className='noimage_mobile_text'>
            {t("goart_open_image")}
          </div>
          {/* <div className='noimage_mobile_desc'>
            {FTLang._("goart_open_image_desc")}
          </div> */}
        </div>
      </div>
    </div>
  );
}
