/*
 * @Author: maofanghai
 * @Date: 2023-08-08 16:59:28
 * @LastEditTime: 2023-08-08 17:08:57
 */
import cuid from "cuid";
import { openDB } from "idb";

const dbName = "FotorFeatureDB";
const STORE = {
  USERUPLOADIMAGE: "userUploadImage"
};

export const FILE_TYPE = {
  LOCAL: "local"
};

class FTFotorFeatureDB {
  db: any;
  imgStore: any;
  constructor() {
    this.db = null;
    this.imgStore = null;
  }
  async initDB() {
    this.db = await openDB(dbName, 1, {
      upgrade(db, oldVersion, newVersion, transaction, event) {
        const imgStore = db.createObjectStore(STORE.USERUPLOADIMAGE);
      },
      blocked(currentVersion, blockedVersion, event) {
        console.log("blocked", currentVersion, blockedVersion, event);
      },
      blocking(currentVersion, blockedVersion, event) {
        console.log("blocking", currentVersion, blockedVersion, event);
      },
      terminated() {
        console.log("terminated");
      }
    });
    console.log("-----db", this.db);
  }

  clearImageCacheStore() {
    this.db.clear(STORE.USERUPLOADIMAGE);
  }

  async addCacheData(file: File, key = cuid()) {
    const res = await this.db.put(STORE.USERUPLOADIMAGE, file, key);
    return { key };
  }

  async getCacheData(key: string) {
    const data = await this.db.get(STORE.USERUPLOADIMAGE, key);
    return data;
  }

  async removeCacheData(fileKey: string) {
    return this.db.delete(STORE.USERUPLOADIMAGE, fileKey);
  }
}

export default new FTFotorFeatureDB();
