/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-07 15:25:55
 * @Description:
 */
import "./FTWatermark.scss";

import { FTCanvasStore, FTImageStore, FTUserStore } from "src/store";
import { IconClose, IconSmallClose, IconVip, IconVipNew } from "src/assets";

import { FTButton } from "src/components";
import { FTLang } from "src/utils";
import React from "react";
import { autorun } from "mobx";
import clsx from "clsx";
import { useLanguage } from "src/hooks";

interface Iprops {
  theme?: "text" | "btn";
  icon?: React.ReactNode;
  children?: React.ReactNode;
  close?: boolean;
  onClose?: (event: any) => void;
}
export default function FTWatermark(props: Iprops) {
  let { theme = "btn", icon, children, close } = props;
  const { t } = useLanguage();
  const [activeBlackFriday, setActiveBlackFriday] = React.useState(false);
  const [showDiscount, setShowDiscount] = React.useState(0);
  let [waterMarkText, setWaterMarkText] = React.useState(
    "goart_remove_watermark"
  );
  let [showWaterMark, setShowWatermark] = React.useState(false);
  const removeWaterMark = () => {
    if (FTUserStore.userInfo) {
      !FTUserStore.isVip &&
        FTUserStore.showPaymnet({
          vipType: "vip",
          position: "goart_remove_watermark",
          otherParam: {
            scenesId: "012"
          }
        });
    } else {
      FTUserStore.showLogin({
        isSignIn: false,
        options: {
          position: "goart_remove_watermark"
        },
        isShowScenses: true,
        otherParam: {
          scenesId: "015",
          width: FTImageStore.currentSize.width,
          height: FTImageStore.currentSize.height,
          imgSrc: FTCanvasStore.canvas!.toDataURL("image/jpeg")
        },
        callback: () => {
          removeWaterMark();
        }
      });
    }
  };

  React.useEffect(() => {
    autorun(() => {
      let watermark = "";
      let {
        showFreeTry,
        showDiscount,
        isVip,
        activeBlackFriday,
        blackFridayGroupA
      } = FTUserStore;
      const { isMobile } = FTCanvasStore;

      let { currentEffect } = FTImageStore;
      const isBlackFriday = activeBlackFriday;
      if (showDiscount !== 0) {
        if (isBlackFriday) {
          watermark = isMobile
            ? "goart_remove_watermark_mobile_discount_blackFriday"
            : "goart_remove_watermark_discount_blackFriday";
        } else if (blackFridayGroupA) {
          watermark = isMobile
            ? "goart_remove_watermark_mobile_try"
            : "goart_remove_watermark_free";
        } else {
          watermark = isMobile
            ? "goart_remove_watermark_mobile_discount"
            : "goart_remove_watermark_discount";
        }
      } else if (showFreeTry) {
        watermark = isMobile
          ? "goart_remove_watermark_mobile_try"
          : "goart_remove_watermark_free";
      } else {
        watermark = isMobile
          ? "goart_remove_watermark_mobile_up"
          : "goart_remove_watermark";
      }
      setWaterMarkText(watermark);
      setShowWatermark(!isVip && currentEffect);
      setActiveBlackFriday(activeBlackFriday);
      setShowDiscount(showDiscount);
    });
  }, []);

  if (!showWaterMark) return <></>;
  const getButtonDescDiscount = () => {
    if (activeBlackFriday) {
      return {
        discount_cn: 6.6,
        discount_en: 34,
        discount_jp: 3.4
      };
    }
    if (showDiscount) {
      return {
        discount_cn: showDiscount / 10,
        discount_en: 100 - showDiscount,
        discount_jp: (100 - showDiscount) / 10
      };
    }
    return null;
  };
  return (
    <FTButton
      onClick={() => removeWaterMark()}
      className={clsx("canvas_extra_remove_btn", theme)}>
      {icon}
      {icon ? null : theme === "text" ? <IconVipNew /> : <IconVip />}
      <span className={clsx("canvas_extra_remove_btn_text")}>
        {showDiscount
          ? t(waterMarkText, getButtonDescDiscount())
          : t(waterMarkText)}
      </span>

      {/* { FTLang._("goart_remove_watermark_normal")} */}
      {children}
      {close && (
        <span className='canvas_extra_remove_btn_close'>
          <IconSmallClose onClick={props.onClose} />
        </span>
      )}
    </FTButton>
  );
}
