/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-02 14:09:48
 * @Description:
 */

import "./FTEffectLoading.scss";

import { FTCanvasStore, FTImageStore } from "src/store";
import React, { useEffect } from "react";
import {
  downloadFile,
  imageLoader,
  rangNumber,
  withPublicUrl,
  withUnit
} from "src/utils";

import FTProgress from "../FTProgress/FTProgress";
import clsx from "clsx";
import urls from "src/constant/url";
import { useLanguage } from "src/hooks";

interface IProps {
  progress?: number;
}

interface IState {
  quotes: string;
}
const defaultImages = [
  "img/effect/aigc-5/5-Cute3D-loop.jpg",
  "img/effect/aigc-6/6-Drawing-loop.jpg",
  "img/effect/aigc-3/3-Cartoon3-loop.jpg",
  "img/effect/sketch/before_after.jpg",
  "img/effect/Ink Art/before_after.jpg",
  "img/effect/aigc-7/7-HandPainting-loop.jpg",
  "img/effect/aigc-9/9-Cartoon-loop.jpg",
  "img/effect/Pencil Drawing/before_after.jpg",
  "img/effect/aigc-2/2-ShineSight-loop.jpg",
  "img/effect/aigc-8/8-VintageComics-loop.jpg",
  "img/effect/Artist's Sketch/before_after.jpg",
  "img/effect/Structuralism/before_after.jpg",
  "img/effect/Cyberpunk/before_after.jpg",
  "img/effect/Sunnyshore/before_after.jpg",
  "img/effect/picaso/before_after.jpg"
];
export default function FTEffectLoading(props: IProps) {
  const [quotes, setQuotes] = React.useState<string>("");
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const timer = React.useRef<any>(null);
  const { t } = useLanguage();

  const changeQuotes = () => {
    const imageIndex = Math.floor(Math.random() * defaultImages.length);
    const url = withPublicUrl(defaultImages[imageIndex]);
    downloadFile(url).then((file) => {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      let index = Math.ceil(Math.random() * 23);
      let quotes: string = "goart_quotes";
      if (index < 10) quotes = quotes + "0" + index;
      else quotes = quotes + index;
      setQuotes(quotes);
      timer.current = setTimeout(() => {
        changeQuotes();
      }, 3000);
    });
  };

  useEffect(() => {
    changeQuotes();
    // defaultImages.forEach(item=>{
    //   const img = new Image();
    //   img.src = withPublicUrl(item);
    //   img.style.display = "none";
    //   document.body.appendChild(img);
    // })
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const { progress = 4 } = props;
  const { isMobile } = FTCanvasStore;
  const newProgress = rangNumber(2, 100, Math.floor(progress));
  return (
    <div
      className={clsx("effect_loading", isMobile && "effect_loading_mobile")}>
      {imageUrl && (
        <div className='effect_loading_img'>
          <img src={imageUrl} alt='' />
        </div>
      )}
      <div
        className='effect_loading_person'
        dangerouslySetInnerHTML={{
          __html: t(quotes)
        }}></div>

      <div className='effect_loading_header'>
        <div className='effect_loading_header_desc'>
          <span>
            {t("goart_drawering", {
              name: t(FTImageStore.currentEffect?.langKey) + " "
            })}
          </span>
        </div>
        <div className='effect_loading_header_desc'>
          {newProgress > 0 && <FTProgress progress={newProgress} />}
          {newProgress > 0 && <div style={{marginLeft:withUnit(16),width:withUnit(50),textAlign:"right"}}>{withUnit(newProgress, "%")}</div>}
        </div>
      </div>
    </div>
  );
}
