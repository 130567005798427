/*
 * @Author: Jiang ShaoDong
 * @Email: jiangshaodong@everimaging.com
 * @Date: 2021-09-14 17:30:18
 * @Description:
 */
import './ScriptModal.scss';

import { FTLang, FreeTryPanel, utils } from 'src/utils';
import { Fragment, useEffect, useState } from 'react';
import { actionActivityClickUp, actionActivityShow } from 'src/analyze/actions';

import { FREE_TRY_DIALOG_DATA } from 'src/constant';
import { FTButton } from 'src/components';
import FTCanvasStore from 'src/store/FTCanvasStore';
import FTUserStore from 'src/store/FTUserStore';
import { IconClose } from 'src/assets';
import { autorun } from 'mobx';
import { useLanguage } from 'src/hooks';

type IProps = {
  type: 'normal' | 'try' | 'discount';
};

export default function ScriptModal(props: IProps) {
  const [showClose, setShowclose] = useState(false);
  const {t} = useLanguage();
  let { type } = props;
  let [isMobile, setIsMobile] = useState(false);
  let localeData: any = FREE_TRY_DIALOG_DATA.filter(
    (item) => item.activity === type,
  )[0].editor;
  let styleType = localeData.styleType || 'free';
  const [isShow, setShow] = useState(false);
  useEffect(() => {
    FreeTryPanel.reset();
    setShow(!FreeTryPanel.checkShowFreeTrialPanel());
    FreeTryPanel.setShowFreeTrialPanel();
    setTimeout(() => {
      setShowclose(true);
    }, 3000);
    return () => {
      console.log('====ScriptModal unmounted');
    };
  }, []);
  useEffect(() => {
    autorun(() => {
      setIsMobile(FTCanvasStore.isMobile);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShow) {
      actionActivityShow(styleType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const __handleClose = (button_position: string) => {
    setShow(false);
    actionActivityClickUp(styleType, 'close');
  };

  const onClick = () => {
    setShow(false);
    actionActivityClickUp(styleType, 'upgrade');
    if (FTUserStore.userInfo) {
      !FTUserStore.isVip &&
        FTUserStore.showPaymnet({
          vipType: 'vip',
          position: 'goart_ad_upgrade',
          otherParam: {
            scenesId: '012',
            promotionId:
              type === 'try'
                ? process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? 665
                  : 536
                : '',
          },
        });
    } else {
      utils.loadLoginScript().then(() => {
        FTUserStore.showLogin({
          isSignIn: false,
          options: {
            position: 'goart_ad_login',
          },
          isShowScenses: true,
          otherParam: {
            scenesId: '015',
          },
          callback: () => {
            onClick();
          },
        });
      });
    }
  };

  return (
    <Fragment>
      {isShow && !isMobile && (
        <div className='modalBG'>
          <div className='modal'>
            {showClose && (
              <span
                className='modal__close'
                onClick={() => {
                  __handleClose('close');
                }}>
                <IconClose />
              </span>
            )}
            {styleType === 'free' && (
              <div className='modal__info'>
                <div className='dailog_left'>
                  <span
                    className='dailog_left_title'
                    dangerouslySetInnerHTML={{
                      __html: t(localeData['title'], {
                        free: `<span>${t(localeData['titleFree'])}</span>`,
                      }),
                    }}></span>
                  <span
                    className='dailog_left_subtitle'
                    dangerouslySetInnerHTML={{
                      __html: t(localeData['subtitle']),
                    }}></span>

                  {Array.isArray(localeData['descList']) &&
                    localeData['descList'].map((m) => (
                      <div className='desc_item' key={m.text}>
                        <img
                          className='icon_svg'
                          src={
                            process.env.REACT_APP_PUB_STATIC_URL +
                            '/static/web/lib/a8s' +
                            m.icon
                          }
                          alt=''
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t(m.text),
                          }}></span>
                      </div>
                    ))}

                  {localeData['tipsTemp'] && (
                    <span className='dailog_tips'>
                      {t(localeData['tipsTemp'])}
                    </span>
                  )}
                  <span className='dailog_tips'>
                    {t(localeData['tips'])}
                  </span>

                  <FTButton type='primary' onClick={onClick}>
                    {t(localeData['upgradeBtn'], {
                      discount_cn: FTUserStore.showDiscount / 10,
                      discount_en: 100 - FTUserStore.showDiscount,
                      discount_jp: (100 - FTUserStore.showDiscount) / 10,
                    })}
                  </FTButton>
                </div>
                <div className='dailog_right'>
                  <img
                    src={process.env.PUBLIC_URL + localeData['rightImg']}
                    alt=''
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
