/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-02 16:55:10
 * @Description:
 */
import React from "react";
import FTCanvasStore from "src/store/FTCanvasStore";
import { IconClose } from "src/assets";
import "./FTImageDownloadMark.scss";
class FTImageDownloadMark extends React.Component {
  close = () => {
    FTCanvasStore.downloadImage = null;
  };
  render() {
    let { downloadImage } = FTCanvasStore;
    return (
      <div className='download_mask' onClick={this.close}>
        <div className='download_mask_img'>
          <img src={downloadImage} alt='' />
        </div>
        <div className='download_mask_close' onClick={this.close}>
          <IconClose />
        </div>
      </div>
    );
  }
}

export default FTImageDownloadMark;
