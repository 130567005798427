
//获取当前使用的浏览器，有助于解决兼容性问题的特殊情况特殊处理
export function myBrowserOS() {
  let u = navigator.userAgent;
  let uLower = navigator.userAgent.toLowerCase();
  return {
    //移动终端浏览器版本信息
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    mac: u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, //是否iPad
    isMobile:
      window.innerWidth <= 750 ||
      /Android|webOS|iPhone|iPod|BlackBerry/i.test(u),
    isSafari: !!uLower.match(/version\/([\d.]+).*safari/),
    isOpera: !!uLower.match(/opera.([\d.]+)/),
    isChrome: !!uLower.match(/chrome\/([\d.]+)/),
    isFirefox: !!uLower.match(/firefox\/([\d.]+)/),
    isEdge: !!uLower.match(/edge\/([\d.]+)/),
    isIE:
      !!uLower.match(/msie ([\d.]+)/) ||
      !!uLower.match(/rv:([\d.]+)\) like gecko/)
  };
}
// import { mobileCommonSdkLoaded } from ".";

// 顶部移动app广告高度，【先初始化一个高度，避免页面跳动】
export const TopMobileAppAdInitHeight = "74px";

// 设置顶部总占用高度。
function setHeightCssVarToBody(height) {
  document.body.style.setProperty("--mobile-top-fixed-height", height);
}
/**
 * 初始化高度
 */
export default function initTopFixedHeight(show) {
  if (!myBrowserOS().isMobile) {
    return;
  }

  let topAppAdHeight = TopMobileAppAdInitHeight;
  if (show !== undefined) {
    topAppAdHeight = show ? TopMobileAppAdInitHeight : "0px";
  }

  // if(window.mobileCommon){
  //   const { componentHeight } = window.mobileCommon.FTTopAppAdInstance();

  //   if (topAppAdHeight !== componentHeight) {
  //     topAppAdHeight = componentHeight; //更新高度
  //     console.log(
  //       "检测到初始高度与SDK返回的不一致，为防止页面跳动，请及时将初始高度保持与SDK返回的一致"
  //     );
  //     setHeightCssVarToBody(topAppAdHeight);
  //   }
  // }

  // mobileCommonSdkLoaded(() => {
  //   const { componentHeight } = window.mobileCommon.FTTopAppAdInstance();
  //   if (TopMobileAppAdInitHeight !== componentHeight) {
  //     topAppAdHeight = componentHeight; //更新高度
  //     console.log(
  //       "检测到初始高度与SDK返回的不一致，为防止页面跳动，请及时将初始高度保持与SDK返回的一致"
  //     );
  //     setHeightCssVarToBody(topAppAdHeight);
  //   }
  // });

  setHeightCssVarToBody(topAppAdHeight);
}
