// 实现值改变的 raf

import { easingFuncs } from "./transition";

export function raf(fn: () => void) {
  if (typeof window === "undefined") return -1;
  return window.requestAnimationFrame(fn);
}

export function cancelRaf(id: number) {
  window.cancelAnimationFrame(id);
}

export function easyInOut(t: number) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

// 周期性的 easyInOut

export function withPeriodFn(fn: (t) => number) {
  return (t) => {
    return t < 0.5 ? easyInOut(t * 2) : easyInOut(2 - 2 * t);
  };
}

interface AnimationOption {
  duration: number;
  infinite?: boolean;
  initValue?: number;
  initTime?: number;
  valueChange?: (value: number) => void;
  finishCallBack?: () => void;
  endFn?: () => boolean;
  getRaf?: (rafId?: number) => void;
  transitionFn?: (t: number) => number;
  timeChange?: (t: number) => void;
}

export function startAnimation(
  props: AnimationOption = {
    duration: 1000
  }
) {
  let startTime = Date.now();
  let rafId = -1;
  const fn = props.transitionFn || easingFuncs.linear;
  const realFn = fn;
  let infinite = props.infinite;
  let count = 0;
  const initValue = props.initValue || 0;
  const loop = () => {
    if (props.endFn?.()) {
      return;
    }
    let now = Date.now();
    let t = (now - startTime) / props.duration;
    // if (count === 0) {
    //   if (props.initTime) {
    //     t = props.initTime;
    //     startTime = now - (t * props.duration);
    //   } else if (initValue) {
    //     let diff = realFn(t) * 100;
    //     while (diff < initValue) {
    //       t += 0.01;
    //       diff = realFn(t) * 100;
    //     }
    //     startTime = now - (t * props.duration);
    //   }
    // }
    if (now - startTime > props.duration) {
      cancelRaf(rafId);
      if (infinite) {
        // 如果需要一直执行下去
        startTime = Date.now();
        rafId = raf(loop);
        props.getRaf?.(rafId);
      } else if (props.finishCallBack) {
        props.finishCallBack();
      }
    } else {
      props.timeChange?.(t);
      const value = realFn(t);
      props.valueChange?.(value);
      rafId = raf(loop);
      props.getRaf?.(rafId);

      count++;
    }
  };
  rafId = raf(loop);
  props.getRaf?.(rafId);
  return {
    calcel: () => cancelRaf(rafId),
    oneTime: () => {
      startTime = Date.now();
      rafId = raf(loop);
    },
    start: () => {
      rafId = raf(loop);
      startTime = Date.now();
    },
    once: () => {
      infinite = false;
    },
    infinite: () => {
      infinite = true;
    }
  };
}

export interface WaterBarAnimationResult {
  calcel: () => void;
  start: () => void;
  down: () => void;
  up: () => void;
  oneTime: () => void;
}

/**
 * 水柱式动画，必须一直保持某种状态向上，不然会下掉状态
 */
export function startWaterBarAnimation(props: {
  duration: number;
  transitionFn?: (k: number) => number;
  valueChange?: (value: number) => void;
}): WaterBarAnimationResult {
  const fn = props.transitionFn || easingFuncs.linear;
  let count = 0;
  let rafId = -1;
  let startTime = Date.now();
  let duration = props.duration;
  let nowValue = 0;
  let prevTime = startTime;
  let upordown = true;
  let needDown = false;
  const loop = () => {
    const needUpOrDown = (() => upordown)();
    let now = Date.now();
    let add = now - prevTime;
    prevTime = now;
    const t = needUpOrDown
      ? Math.min(count + add, duration)
      : Math.max(0, count - add);
    nowValue = fn(t / duration);
    props.valueChange?.(nowValue);
    const precent = nowValue * 100;
    if (t === 0) {
      cancelRaf(rafId);
    } else {
      rafId = raf(loop);
      count = t;
    }
    if (Math.round(precent) === 100) {
      if (needDown) {
        upordown = false;
        needDown = false;
        rafId = raf(loop);
      }
    }
  };
  return {
    calcel: () => cancelRaf(rafId),
    oneTime: () => {
      upordown = true;
      needDown = true;
      startTime = Date.now();
      prevTime = startTime;
      rafId = raf(loop);
    },
    start: () => {
      upordown = true;
      rafId = raf(loop);
      startTime = Date.now();
      prevTime = startTime;
    },
    down: () => {
      upordown = false;
    },
    up: () => {
      upordown = true;
    }
  };
}
