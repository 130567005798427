/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2022-04-27 14:20:41
 * @Description:
 */
class FTAnalyticBase {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}
  addPV(url: any) {}
  addEvent(event: any) {}
}
export default FTAnalyticBase;
