/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-13 10:06:09
 * @Description:
 */
import React from "react";
import {
  FotorLogoIcon,
  FotorLogoIconLarger,
  FotorLogoIconSmall,
  IconPlus,
  IconPlusLarger,
  IconPlusSmall
} from "src/assets";
import FTImageStore from "src/store/FTImageStore";
import FTLang from "src/utils/FTLang";
import FTButton from "src/components/FTButton/FTButton";
import "./FTCreateNow.scss";
import { autorun } from "mobx";
import FTCanvasStore from "src/store/FTCanvasStore";

class FTCreateNow extends React.Component {
  state = {
    isMobile: false
  };

  componentDidMount() {
    autorun(() => {
      let { isMobile } = FTCanvasStore;
      this.setState({
        isMobile
      });
    });
  }
  uploadImage = () => {
    FTImageStore.uploadImage("upload_image");
  };

  render() {
    let { isMobile } = this.state;
    return (
      <div className='create_now'>
        <div className='create_now_background'>
          {/* 原图 */}
          {!isMobile && (
            <img
              className='create_now_background_normal'
              src={`${process.env.PUBLIC_URL}/img/background/normal_L.jpg`}
              alt=''
            />
          )}
          {/* 结果图 */}
          {!isMobile && (
            <img
              className='create_now_background_effect'
              src={`${process.env.PUBLIC_URL}/img/background/effect_L.jpg`}
              alt=''
            />
          )}
          {/* 原图 */}
          {isMobile && (
            <img
              className='create_now_background_normal'
              src={`${process.env.PUBLIC_URL}/img/background/normal_S.jpg`}
              alt=''
            />
          )}
          {/* 结果图 */}
          {isMobile && (
            <img
              className='create_now_background_effect'
              src={`${process.env.PUBLIC_URL}/img/background/effect_S.jpg`}
              alt=''
            />
          )}
        </div>
        <div
          className={`create_now_center ${isMobile ? "create_now_center_mobile" : ""}`}
        >
          <div className='create_now_center_container'>
            <div className='create_now_center_icon'>
              {isMobile ? <FotorLogoIconSmall /> : <FotorLogoIconLarger />}
            </div>
            <div className='create_now_center_text'>
              {FTLang._("goart_create_now_title")}
            </div>
            <div className='create_now_center_btn'>
              <FTButton
                className='noimage_btn'
                onClick={() => this.uploadImage()}
              >
                {isMobile ? <IconPlusSmall /> : <IconPlusLarger />}
                <span> {FTLang._("goart_create_now")} </span>
              </FTButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FTCreateNow;
