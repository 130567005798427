import "./ToolLeftSearchList.scss";

import FTLang from "src/utils/FTLang";
/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 11:26:46
 * @Description:
 */
import React from "react";
import { SearchEmptyList } from "src/assets";
interface IProps {
  searchKey:string;
}
export default function ToolLeftNoSearchList(props: IProps) {
  return (
    <div className='search_list_no_list'>
      <img src={SearchEmptyList} alt='' />
      <span>{FTLang._("goart_effect_no_result",{searchKey:props.searchKey})}</span>
    </div>
  );
}
