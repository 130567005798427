import "./FTCompare.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { cancelRaf, startAnimation } from "./animation";
import { isNoReactSnap, withPublicUrl, withUnit } from "src/utils";

import clsx from "clsx";
import { easingFuncs } from "./transition";

interface Iprops {
  originalUrl: string;
  originalAlt: string;
  cutoutUrl: string;
  cutoutAlt: string;
  className?: string;
  style?: React.CSSProperties;
  animation?: boolean;
  isMobile?: boolean;
  onFinish?: () => void;
  onNext?: () => void;
  onAnimation?: () => void;
  dataKey?: string;
}
export default function FTCompareV3(props: Iprops) {
  let {
    dataKey,
    originalUrl,
    originalAlt,
    cutoutUrl,
    cutoutAlt,
    className,
    animation = false,
    style,
    isMobile = false,
    onFinish
  } = props;
  const [isDown, setIsDown] = React.useState(false);
  const isEnter = React.useRef(false);
  const [loaded1, setLoaded1] = React.useState(false);
  const [loaded2, setLoaded2] = React.useState(false);
  const refImg1 = React.useRef(null);
  const refImg2 = React.useRef(null);
  let ref = React.useRef(null);
  const isAnimation = React.useRef<boolean>(false);
  const rafId = useRef<number>(-1);
  const rafTime = useRef<number>(0);
  const compareIcon = React.useRef<HTMLDivElement>(null);
  const compareOriginal = React.useRef<HTMLDivElement>(null);
  const [offsetValue, setOffsetValue] = useState(50);
  const animationRef = useRef<any>(null);

  const startCompareAnimation = useCallback(() => {
    endCompareAnimation();
    isAnimation.current = true;
    animationRef.current = startAnimation({
      duration: 2500,
      infinite: isEnter.current,
      initTime: 0,
      initValue: 0,
      transitionFn: easingFuncs.circelSine,
      valueChange: (value: number) => {
        if (compareOriginal.current) {
          const percentValue = (value * 100 + 100) / 2;
          compareOriginal.current!.style.width = withUnit(percentValue, "%");
        }
      },
      finishCallBack: () => {
        if (!isEnter.current) {
          onFinish?.();
          isAnimation.current = false;
        }

        if (compareOriginal.current) {
          compareOriginal.current.style.width = withUnit(50, "%");
        }
        setOffsetValue(100);
      },
      timeChange: (t) => {
        rafTime.current = t;
      },
      getRaf: (raf) => {
        if (rafId.current && raf) {
          rafId.current = raf;
        }
      }
    });
  }, [onFinish]);
  const endCompareAnimation = () => {
    animationRef.current?.calcel();
    isAnimation.current = false;
    if (compareOriginal.current) {
      compareOriginal.current.style.width = withUnit(50, "%");
    }
  };
  useEffect(() => {
    return () => {
      endCompareAnimation();
    };
  }, []);

  React.useEffect(() => {
    if (isNoReactSnap()) {
      if (animation && loaded1 && loaded2) {
        if (!isAnimation.current) {
          endCompareAnimation();
          startCompareAnimation();
        }
      } else if (loaded1 && loaded2) {
        endCompareAnimation();
      }
      // }
    }
  }, [animation, loaded1, loaded2, startCompareAnimation]);

  // React.useEffect(() => {
  //   handleRemoveEventListener();
  //   handleAddEventListener();
  //   return () => {
  //     handleRemoveEventListener();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isDown]);

  const handleAddEventListener = () => {
    document.body.addEventListener("mousemove", handleMouseMove);
    document.body.addEventListener("mouseup", handleMouseUp);
    document.body.addEventListener("touchmove", handleMouseMove);
    document.body.addEventListener("touchend", handleMouseUp);
  };

  const handleRemoveEventListener = () => {
    document.body.removeEventListener("mousemove", handleMouseMove);
    document.body.removeEventListener("mouseup", handleMouseUp);
    document.body.removeEventListener("touchmove", handleMouseMove);
    document.body.removeEventListener("touchend", handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDown(false);
    setTimeout(() => {
      if (animation) {
        startCompareAnimation();
      }
    }, 1000);
  };

  const handleMouseDown = () => {
    endCompareAnimation();
    setIsDown(true);
  };

  const handleMouseMove = (e: any) => {
    if (isDown) {
      if (ref.current && compareOriginal.current && compareIcon.current) {
        let container = ref.current as HTMLDivElement,
          original = compareOriginal.current as HTMLDivElement,
          icon = compareIcon.current as HTMLDivElement;
        let boundRect = container.getBoundingClientRect();
        if (e.touches) {
          e = e.touches[0];
        }
        let offset =
          (((e.x || e.clientX) - boundRect.x) / boundRect.width) * 100;
        if (isMobile) {
          offset = Math.max(5, offset);
          offset = Math.min(offset, 95);
        } else {
          offset = Math.max(0, offset);
          offset = Math.min(offset, 100);
        }
        original.style.width = `${offset}%`;
        icon.style.left = `${offset}%`;
        setOffsetValue(offset);
      }
    }
  };

  const handleMouseEnter = () => {
    if (!loaded1 || !loaded2) {
      return;
    }
    isEnter.current = true;
    if (!isAnimation.current) {
      startAnimation();
    }else {
      animationRef.current.infinite?.();
    }

    props.onAnimation?.();
    if (isAnimation.current) {
      startCompareAnimation();
    }
  };
  const handleMouseOut = () => {
    if (!loaded1 || !loaded2) {
      return;
    }
    isEnter.current = false;
    // endCompareAnimation();
    // props.onNext?.();

    animationRef.current?.once?.();
  };

  const isLoaded = loaded1 && loaded2;

  return (
    <div
      className={clsx("image_compare_sample", className)}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
      ref={ref}>
      <div className='compare_cutout'>
        <img
          className={clsx(!loaded1 && "loading-skeleton-back2 ")}
          ref={refImg1}
          draggable={false}
          // data-src={process.env.PUBLIC_URL + cutoutUrl}
          src={withPublicUrl(cutoutUrl)}
          alt={cutoutAlt}
          onLoad={() => {
            setTimeout(() => {
              setLoaded1(true);
            }, 100);
          }}
        />
      </div>
      <div
        className={clsx(
          "compare_original",
          loaded2 && "compare_original_border"
        )}
        ref={compareOriginal}>
        <img
          ref={refImg2}
          className={clsx(!loaded2 && "loading-skeleton-back2 ")}
          draggable={false}
          // data-src={process.env.PUBLIC_URL + originalUrl}
          src={withPublicUrl(originalUrl)}
          alt={originalAlt}
          onLoad={() => {
            setTimeout(() => {
              setLoaded2(true);
            }, 200);
          }}
        />
      </div>
      {!isLoaded && <div className='loading-skeleton-back2'></div>}
    </div>
  );
}
