import FTButton from '../FTButton';
import styles from './index.module.scss';

type TipCardProps = {
  videoSrc: string;
  videoPoster: string;
  title: string;
  desc: any;
  gotItButtonText: string;
  onClickGotIt: () => void;
}

const TipCard = (props: TipCardProps) => {
  const {
    videoSrc,
    videoPoster,
    title,
    desc,
    gotItButtonText,
    onClickGotIt,
  } = props;

  return (
    <div className={styles["tip_card"]}>
      <div className={styles["video_wrapper"]}>
        <video 
          autoPlay
          loop
          muted
          playsInline
          src={videoSrc}
          poster={videoPoster}
        />
      </div>

      <p className={styles["title"]}>{title}</p>

      <p className={styles["desc"]}>{desc}</p>

      <div className={styles["button_row"]}>
        <FTButton type='primary' className={styles["got_it_button"]} onClick={onClickGotIt}>{gotItButtonText}</FTButton>
      </div>
    </div>
  )
}

export default TipCard;