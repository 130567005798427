/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2022-01-17 17:15:58
 * @Description: 
 */

interface CommonObject { [key: string]: any }
class FTEventTarget {
  _firing: boolean;
  _removeHandlers: Array<any>;
  static instance: FTEventTarget | undefined
  handlers: CommonObject;
  constructor() {
    this._firing = false;
    this._removeHandlers = [];
    this.handlers = {};
  }

  static getInstance() {
    /*单例模式*/
    if (!FTEventTarget.instance) {
      FTEventTarget.instance = new FTEventTarget();
    }
    return FTEventTarget.instance;
  }

  addEvent(type: string, handler: any, currentTarget?: any) {
    if (currentTarget == null) currentTarget = undefined;

    if (typeof this.handlers[type] == "undefined") {
      this.handlers[type] = [];
    }
    //将处理事件push到事件处理数组里面
    this.handlers[type].push({
      handler,
      currentTarget
    });
  }
  /**
   * @param event {Object} { type: "dragOver", target: e }
   * */
  fireEvent(event: any) {
    if (!event.target) {
      event.target = this;
    }

    if (this.handlers[event.type] instanceof Array) {
      let handlers = this.handlers[event.type];
      this._firing = true;
      for (let i = 0; i < handlers.length; i++) {
        let evt = handlers[i];
        if (evt && evt.handler) evt.handler(event, evt.currentTarget);
      }
      this._firing = false;

      for (let i = this._removeHandlers.length - 1; i >= 0; i--) {
        let data = this._removeHandlers[i];
        this.removeEvent(data.type, data.handler, data.target);
        this._removeHandlers.splice(i, 1);
      }
    }
  }

  removeEvent(type: string, handler: any, currentTarget?: any) {
    if (this.handlers[type] instanceof Array) {
      if (this._firing) {
        this._removeHandlers.push({
          type: type,
          handler: handler,
          target: currentTarget
        });
        return;
      }

      let handlers = this.handlers[type];
      //在同一个事件类型下的可能存在多种处理事件
      for (let i = 0; i < handlers.length; i++) {
        //找出本次需要处理的事件下标
        let evt = handlers[i];
        if (evt == null) {
          continue;
        }

        if (evt.handler === handler && evt.currentTarget === currentTarget) {
          evt.handler = null;
          evt.currentTarget = null;
          evt = null;
          handlers[i] = null;
          //从事件处理数组里面删除
          handlers.splice(i, 1);
          break;
        }
      }
    }
  }
}

export const FTEVENT_CONSTANTS = {
  USERINFO_CHANGED: "userinfo_changed",
  USER_VIP_CHANGED: "user_vip_changed",
  CHNAGE_LANGUAGE: "change_language",
  CHANGE_AD_SHOW: "change_ad_show",
  SCROLL_TO_VIEW: "scroll_to_view",

}

export default FTEventTarget;

