import { useImageStoreFN, useLanguage } from "src/hooks";

import { FTImageStore } from "src/store";
import { IconClose } from "src/assets";
import { ReactComponent as IconFailed } from "src/assets/svg/failed.svg";
import React from "react";
import styles from "./RequestErrorModal.module.scss";

interface IProps {
  onClose?: () => void;
}

export default function RequestErrorModal(props: IProps) {
  const show = useImageStoreFN((state) => state.showRequestErrorModal);
  let { t } = useLanguage();

  const handleClose = () => {
    FTImageStore.showRequestErrorModal = false;
  };
  const handleRetry = () => {
    FTImageStore.showRequestErrorModal = false;
    FTImageStore.getGoartEffect(FTImageStore.currentEffect, "retry_button");
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.mask} />
      <div className={styles.body} onClick={handleClose}>
        <div className={styles.close}>
          <IconClose />
        </div>

        <div className={styles.header}>
          <div>
            <IconFailed />
          </div>
          <div className={styles.header_right}>
            <div className={styles.title}>{t("network_error_title")}</div>
            <div className={styles.content}>
              {t("network_error_content")}
            </div>
          </div>
        </div>
        <div className={styles.foot}>
          <button className={styles.btn} onClick={handleClose}>
            {t("network_error_cancel")}
          </button>
          <button className={styles.primary} onClick={handleRetry}>
            {t("network_error_retry")}
          </button>
        </div>
      </div>
    </div>
  );
}
