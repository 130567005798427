import "./ToolLeftSearchList.scss";

import { useCurrentEffect, useLanguage } from "src/hooks";

import EffectListCollapseItem from "../ToolLeftEffectList/EffectListCollapseItem/EffectListCollapseItem";
import FTSkeleton from "src/components/FTSkeleton";
import { IEffectItem } from "src/store/types";
/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 11:26:46
 * @Description:
 */
import React from "react";
import ToolLeftNoSearchList from "./ToolLeftNoSearchList";
import clsx from "clsx";

interface Iprops {
  searchKey: string;
  currentClassify?: string;
  searchLoading: boolean;
  searchList: IEffectItem[];
  showTitle?: boolean;
  loadingClassName?: string;
  loadingStyle?: React.CSSProperties;
  /** 是否显示new标签 */
  showNew?:boolean
}
export default function ToolLeftSearchList(props: Iprops) {
  let {
    searchList,
    searchKey,
    currentClassify,
    searchLoading,
    showTitle = true,
    loadingClassName,
    loadingStyle,
    showNew = true
  } = props;
  const { t,language } = useLanguage();
  let currentEffect = useCurrentEffect();
  // let [loading, setLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, [searchList]);

  const newEffectList = React.useMemo(() => {
    let newEffectList: IEffectItem[][] = [];
    for (let i = 0; i < searchList.length; i += 3) {
      newEffectList.push(searchList.slice(i, i + 3));
    }
    return newEffectList;
  }, [searchList,language,currentClassify]);

  if (searchLoading) {
    return (
      <div className={clsx("search_list_loading", loadingClassName)} style={loadingStyle}>
        <FTSkeleton num={18} />
        {/* <img src={CanvasLoadingLarger} alt='' /> */}
      </div>
    );
  }

  return (
    <div className='search_list'>
      {newEffectList.length > 0 && showTitle && (
        <div className='search_list_numbers'>
          {t("goart_search_title_result", {
            number: searchList.length
          })}
        </div>
      )}
      {newEffectList.length > 0 ? (
        newEffectList.map((item, index) => {
          return (
            <div key={index}>
              {item.map((ite: any) => {
                const id = ite.id || ite.prodId;
                return (
                  <EffectListCollapseItem
                    needCollapse={true}
                    key={id}
                    effect={ite}
                    showNew={showNew}
                    active={(currentEffect?.id??"") === id}
                  />
                );
              })}
            </div>
          );
        })
      ) : (
        <ToolLeftNoSearchList searchKey={searchKey} />
      )}
    </div>
  );
}
