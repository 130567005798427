/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-10 17:47:27
 * @Description:
 */

import './FTDownloadMobile.scss';

import { FTCanvasStore, FTImageStore, FTUserStore } from 'src/store';
import { FTLang, utils } from 'src/utils';
import { actionStartDownload, actionTryDownload } from 'src/analyze/actions';

import { FTButton } from 'src/components';
import { IconVip } from 'src/assets';
import React from 'react';
import { autorun } from 'mobx';
import { imageProcesserSize } from 'src/constant';

class FTDownloadMobile extends React.Component {
  state = {
    showWaterMark: false,
    smallSize: {
      width: 0,
      height: 0,
    },
    largerSize: {
      width: 0,
      height: 0,
    },
  };
  componentDidMount() {
    autorun(() => {
      let { currentEffect, currentSize, acturalSize } = FTImageStore;
      let { isVip } = FTUserStore;
      let smallScale = utils.aspectFitScale(
        currentSize.width,
        currentSize.height,
        imageProcesserSize.IMAGE_SIDE_VALUE,
      );
      let largerScale = utils.aspectFitScale(
        acturalSize.width,
        acturalSize.height,
        Number(imageProcesserSize.SUPPER_RESOLUTION_HIGH_ID),
      );

      this.setState({
        showWaterMark: !isVip && currentEffect,
        smallSize: {
          width: Math.round(currentSize.width * smallScale),
          height: Math.round(currentSize.height * smallScale),
        },
        largerSize: {
          width: Math.round(acturalSize.width * largerScale),
          height: Math.round(acturalSize.height * largerScale),
        },
      });
    });
  }
  downloadPreview = () => {
    actionTryDownload();
    actionStartDownload('download_preview');
    FTCanvasStore.downloadPreview();
  };

  downloadFullSize = () => {
    if (!FTUserStore.userInfo) {
      FTUserStore.showLogin({
        isSignIn: false,
        options: {
          position: 'goart_download_fullsize',
        },
        isShowScenses: true,
        otherParam: {
          scenesId: '010',
          width: FTImageStore.currentSize.width,
          height: FTImageStore.currentSize.height,
          imgSrc: FTCanvasStore.canvas!.toDataURL('image/jpeg'),
        },
        callback: () => {
          this.downloadFullSize();
        },
      });
    } else {
      if (!FTUserStore.isVip) {
        FTUserStore.showPaymnet({
          vipType: 'vip',
          position: 'goart_download_hd',
          otherParam: {
            scenesId: '012',
          },
          callback: () => {
            FTCanvasStore.downloadFullSize();
          },
        });
      } else {
        actionTryDownload();
        actionStartDownload('download_fullsize');
        FTCanvasStore.downloadFullSize();
      }
    }
  };

  render() {
    let { smallSize, largerSize } = this.state;
    return (
      <div className='download_mobile'>
        <div
          className='download_mobile_select'
          onClick={(e) => {
            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();
          }}>
          <FTButton
            className='download_mobile_select_small'
            onClick={() => this.downloadPreview()}>
            {/* <div className="download_mobile_select_small_icon">
            <IconPreview />
          </div> */}
            <span>{FTLang._('goart_preview')}</span>
            <span className='download_mobile_select_small_size'>
              {`${smallSize.width} x ${smallSize.height} px`}
            </span>
          </FTButton>
          <FTButton
            className='download_mobile_select_larger'
            type='primary'
            onClick={() => this.downloadFullSize()}>
            {/* <div className="download_mobile_select_larger_icon">
            <IconHighD />
          </div> */}
            <span>{FTLang._('goart_fullsize')}</span>
            <span className='download_mobile_select_larger_size'>
              {`${largerSize.width} x ${largerSize.height} px`}
            </span>
            <IconVip />
          </FTButton>
        </div>
      </div>
    );
  }
}

export default FTDownloadMobile;
