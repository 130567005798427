/**
 * 获取对象的类型字符串
 *
 * @param obj
 * @returns {"Object"|"Number"|"string"|string}
 */
export const getObjectTypeString = (obj: any): string => {
  return Object.prototype.toString.call(obj).slice(8, -1);
};
export const getObjectType = (obj: any): string => {
  return Object.prototype.toString.call(obj);
};
