/* 移动端 渠道链接  */

const deeplinks: { [key: string]: any } = {
  //开发环境
  development: {
    ios: {
      homepage_nav_button:
        "https://app.adjust.com/mh8zuw6?campaign=goart.fotor.com%2F&adgroup=inner-button&deep_link=fotor%3A%2F%2FfuntionExperience%2Fhome%3Ftype%3Dai_art_effects%26channel%3Dweb%26url%3Dgoart.fotor.com%2F%26adgroup%3Dinner-button",
        dialog_open_app: 'https%3A%2F%2Fapp.adjust.com%2F5t0c3sp_mh8zuw6%3Fcampaign%3Dtest-goart.fotor.com%252F%26adgroup%3Denter_popup%26deep_link%3Dfotor%253A%252F%252FgoArtFx%253Fchannel%253Dweb%2526url%253Dtest-goart.fotor.com%252F%2526adgroup%253Denter_popup%26redirect_ios%3Dhttps%253A%252F%252Fapps.apple.com%252Fapp%252Ffotor-ai-photo-editor%252Fid440159265%253Fppid%253Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d%26redirect_android%3Dhttps%253A%252F%252Fplay.google.com%252Fstore%252Fapps%252Fdetails%253Fid%253Dcom.everimaging.photoeffectstudio%2526listing%253Dai-cartoon'
    },
    android: {
      homepage_nav_button:"https://app.adjust.com/5t0c3sp?campaign=goart.fotor.com%2F&adgroup=inner-button&deep_link=fotor%3A%2F%2FfuntionExperience%2Fhome%3Ftype%3Dai_art_effects%26channel%3Dweb%26url%3Dgoart.fotor.com%2F%26adgroup%3Dinner-button",
      dialog_open_app: 'https%3A%2F%2Fapp.adjust.com%2F5t0c3sp_mh8zuw6%3Fcampaign%3Dtest-goart.fotor.com%252F%26adgroup%3Denter_popup%26deep_link%3Dfotor%253A%252F%252FgoArtFx%253Fchannel%253Dweb%2526url%253Dtest-goart.fotor.com%252F%2526adgroup%253Denter_popup%26redirect_ios%3Dhttps%253A%252F%252Fapps.apple.com%252Fapp%252Ffotor-ai-photo-editor%252Fid440159265%253Fppid%253Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d%26redirect_android%3Dhttps%253A%252F%252Fplay.google.com%252Fstore%252Fapps%252Fdetails%253Fid%253Dcom.everimaging.photoeffectstudio%2526listing%253Dai-cartoon'
    }
  },

  //正式环境
  production: {
    ios: {
      homepage_nav_button:
        "https://app.adjust.com/mh8zuw6?campaign=goart.fotor.com%2F&adgroup=inner-button&deep_link=fotor%3A%2F%2FfuntionExperience%2Fhome%3Ftype%3Dai_art_effects%26channel%3Dweb%26url%3Dgoart.fotor.com%2F%26adgroup%3Dinner-button",
      dialog_open_app: 'https%3A%2F%2Fapp.adjust.com%2F5t0c3sp_mh8zuw6%3Fcampaign%3Dgoart.fotor.com%252F%26adgroup%3Denter_popup%26deep_link%3Dfotor%253A%252F%252FgoArtFx%253Fchannel%253Dweb%2526url%253Dgoart.fotor.com%252F%2526adgroup%253Denter_popup%26redirect_ios%3Dhttps%253A%252F%252Fapps.apple.com%252Fapp%252Ffotor-ai-photo-editor%252Fid440159265%253Fppid%253Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d%26redirect_android%3Dhttps%253A%252F%252Fplay.google.com%252Fstore%252Fapps%252Fdetails%253Fid%253Dcom.everimaging.photoeffectstudio%2526listing%253Dai-cartoon'
    },
    android: {
      homepage_nav_button:"https://app.adjust.com/5t0c3sp?campaign=goart.fotor.com%2F&adgroup=inner-button&deep_link=fotor%3A%2F%2FfuntionExperience%2Fhome%3Ftype%3Dai_art_effects%26channel%3Dweb%26url%3Dgoart.fotor.com%2F%26adgroup%3Dinner-button",
      dialog_open_app: 'https%3A%2F%2Fapp.adjust.com%2F5t0c3sp_mh8zuw6%3Fcampaign%3Dgoart.fotor.com%252F%26adgroup%3Denter_popup%26deep_link%3Dfotor%253A%252F%252FgoArtFx%253Fchannel%253Dweb%2526url%253Dgoart.fotor.com%252F%2526adgroup%253Denter_popup%26redirect_ios%3Dhttps%253A%252F%252Fapps.apple.com%252Fapp%252Ffotor-ai-photo-editor%252Fid440159265%253Fppid%253Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d%26redirect_android%3Dhttps%253A%252F%252Fplay.google.com%252Fstore%252Fapps%252Fdetails%253Fid%253Dcom.everimaging.photoeffectstudio%2526listing%253Dai-cartoon'
    }
  }
};
deeplinks.staging = deeplinks.development;
deeplinks.preRelease = deeplinks.production;
const env =
  process.env.NODE_ENV === "production" ? "production" : "development";
export default deeplinks[env];
