import "./ToolLeftSearch.scss";

/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 09:30:28
 * @Description:
 */
import { Input, Popover } from "antd";
import { useLanguage, useSearchText } from "src/hooks";

import FTImageStore from "src/store/FTImageStore";
import { IconClose } from "src/assets";
import React from "react";
import ToolLeftSearchTags from "./ToolLeftSearchTags/ToolLeftSearchTags";
import ToolLeftSearchTagsDown from "./ToolLeftSearchTags/ToolLeftSearchTagsDown";
import clsx from "clsx";

export default function ToolLeftSearch() {
  let [searchText, setSearchText] = useSearchText();
  let [inputValue, setInputValue] = React.useState("");
  const { t, language } = useLanguage();
  const [visible, setVisible] = React.useState(false);

  let updateTimer = React.useRef<any>(null);
  const handleClear = () => {
    setSearchText("");
    FTImageStore.currentClassify = "";
    FTImageStore.currentClassifyId = "";
    if (FTImageStore.selectEffectTags && FTImageStore.selectEffectTags.length) {
      FTImageStore.clearSelectTags();
    }
    FTImageStore.searchList = [];
    setVisible(true);
  };

  React.useEffect(() => {
    setInputValue(searchText);
    if (searchText) {
      setVisible(false);
    }
  }, [searchText]);

  const handleChange = (value: any) => {
    setInputValue(value.target.value);
    if (updateTimer.current) {
      clearTimeout(updateTimer.current);
      updateTimer.current = null;
    }
    updateTimer.current = setTimeout(() => {
      setSearchText(value.target.value);
      if (FTImageStore.selectEffectTags.length) {
        FTImageStore.clearSelectTags();
      }
      FTImageStore.currentClassify = "";
      FTImageStore.currentClassifyId = "";
    }, 1000);
    if(value.target.value !== ""){
      setVisible(false);
      FTImageStore.clearSelectTags();
    }else{
      setVisible(true);
    }
  };

  const handleFocus = (e: any) => {
    e.target.setAttribute("autocomplete", "off");
    // if (FTImageStore.selectEffectTags.length) {
    //   setInputValue("");
    // }
  };

  const handleBlur = () => {
    if (FTImageStore.selectEffectTags.length) {
      setInputValue(searchText);
    }
  };

  const handleChoseTags = (tag) => {
    FTImageStore.addSelectTags(tag);
    setVisible(false);
  };
  const showClassify = searchText.trim().length === 0;
  return (
    <div className='toolleft_search' data-lang={language}>
      <Popover
        visible={searchText ? false : visible}
        content={<ToolLeftSearchTags handleChoseTags={handleChoseTags} />}
        trigger={"click"}
        placement='bottom'
        align={{
          offset: [0, -5]
        }}
        getPopupContainer={(triggerNode) =>
          document.querySelector(".layout-content") as HTMLElement
        }
        onVisibleChange={(visible) => {
          setVisible(visible);
        }}
        overlayClassName={clsx("toolleft_search_popover")}>
        <Input
          className={clsx(
            "toolleft_search_input",
            // !inputValue && "toolleft_search_empty",
            // visible && "toolleft_search_input_focus"
          )}
          autoComplete='off'
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={inputValue}
          onChange={handleChange}
          style={{ width: "100%" }}
          suffix={
            <IconClose
              className={clsx("icon_close", !inputValue && "icon_close_hide")}
              style={{ width: "10px", height: "10px" }}
              onClick={handleClear}
            />
          }
          placeholder={t("goart_search_placeholder")}
        />
      </Popover>
      {showClassify&&<ToolLeftSearchTagsDown />}
    </div>
  );
}
