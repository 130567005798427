/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 13:58:17
 * @Description:
 */

import "./NoImageMask.scss";

import { FTButton, FTFullDragUpload, FTSampleList } from "src/components";
import { FTCanvasStore, FTImageStore } from "src/store";
import {
  FTFotorFeatureDB,
  FTLang,
  hanldelFTUserProfile,
  utils,
  withUnit
} from "src/utils";
import { IconMagicWand, IconOpenImage } from "src/assets";
import { MAX_WIDTH, urls } from "src/constant";
import React, { useEffect } from "react";
import { actionClickUp, actionFeatureClickUp, actionImportImageResult } from "src/analyze/actions";
import { autorun, set } from "mobx";
import { useLanguage, useQueryRoute } from "src/hooks";

import { ExampleEffectItem } from "src/store/types";
import loadable from "@loadable/component";
import { message } from "antd";
import qs from "qs";

const FTMoreLink = loadable(
  () =>
    import(
      /* webpackChunkName: "more-link" */
      "src/components/FTMoreLink/FTMoreLink"
    ),
  { ssr: false }
);
export default function NoImageMask() {
  const [exampleList, setExampleList] = React.useState<ExampleEffectItem[]>([]);
  useQueryRoute()
  const uploadImage = async (e: any) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    actionFeatureClickUp("get_started")
    FTImageStore.uploadImage("upload_image");
  };

  const handleFullSelectImage = async (blobs: File[]) => {
    const [firstBlob] = blobs;
    actionFeatureClickUp("get_started")
    FTImageStore.uploadImage("drop_upload", firstBlob);
  };
  useEffect(() => {
    const page = qs.parse(window.location.search.slice(1));
    async function getIndex(db_key: string) {
      await FTFotorFeatureDB.initDB();
      const data = await FTFotorFeatureDB.getCacheData(db_key);
      FTImageStore.uploadImage("upload_image", data);
    }

    function send(url: string) {
      var blob: Blob;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Accept", "image/jpeg");
      xhr.responseType = "blob";
      // 加载时处理
      xhr.onload = () => {
        // 获取返回结果
        blob = xhr.response;
        let file = new File([blob], "fotor.jpg", { type: "image/jpeg" });
        FTImageStore.uploadImage("upload_image", file);

        // 返回结果
      };
      xhr.onerror = (e) => {};
      // 发送
      xhr.send();
    }

    let hasClear = false;
    if (page?.db_key) {
      hasClear = true;
      getIndex(page.db_key as string);
    }

    if (page?.bg_url) {
      hasClear = true;
      send(page.bg_url as string);
    }

    if (hasClear) {
      window.history.replaceState(null, "", window.location.pathname);
    }
    const dispose = autorun(() => {
      const { examplesList } = FTImageStore;
      setExampleList(examplesList);
    });
    return () => {
      dispose();
    };
  }, []);

  const dropImage = async (e: any) => {
    if (e.dataTransfer && e.dataTransfer.files.length) {
      actionImportImageResult("drop_upload", true);
      hanldelFTUserProfile();
      FTCanvasStore.changeLoading(true);
      let file = e.dataTransfer.files[0];
      const checkResult = await utils.checkImage(file);
      if (!checkResult.result) {
        message.error(t(checkResult.message));
        FTCanvasStore.changeLoading(false);
        return;
      }

      let {
        canvas: imageElement,
        acturalSize,
        currentSize
      } = await utils.getImageFromFile(file);
      FTImageStore.setCurrentImageElement(
        imageElement,
        currentSize,
        acturalSize
      );
      FTImageStore.setCurrentImage(file);
      let effect =
        FTImageStore.effectClassifyMap.length &&
        FTImageStore.effectClassifyMap[0].list[0];
      if (effect) {
        FTImageStore.getGoartEffect(effect);
        actionClickUp(
          utils.toFixed(effect.id as unknown as number, 0),
          effect.name
        );
      }
      FTCanvasStore.changeLoading(false);
    } else {
      actionImportImageResult("drop_upload", false);
    }
  };

  const handleSampleEffect = (effect: ExampleEffectItem) => {
    FTImageStore.startSampleItem(effect);
  };
  const { t } = useLanguage();
  return (
    <div className='noimage' onClick={(e) => uploadImage(e)}>
      <div className='noimage_bg'>
        <img src={urls.assetsUrl + "/img/background/bg.webp"} alt='' />
        <div className='noimage_bg_mask'></div>
      </div>
      <FTFullDragUpload onDropFiles={(files) => handleFullSelectImage(files)} />

      <div className='noimage_content'>
        <div className='noimage_content_header'>
          <div
            className='noimage_content_header_content'
            dangerouslySetInnerHTML={{
              __html: t("noimage_title")
            }}></div>
          <div className='noimage_content_header_icon'>
            <IconMagicWand />
          </div>
        </div>
        <div className='noimage_content_drag_panel'>
          <div className='noimage_text'>{t("goart_drag_open")}</div>
          <FTButton
            type='primary'
            className='noimage_btn'
            onClick={(e) => uploadImage(e)}>
            <IconOpenImage style={{ marginRight: withUnit(8) }} />
            <span> {t("goart_open_image")} </span>
          </FTButton>
        </div>
        <div className='noimage_content_sample_wrapper'>
          <FTSampleList
            list={exampleList}
            onEffect={handleSampleEffect}></FTSampleList>
        </div>
      </div>
      <div className="noimage_right_bottom">
        <FTMoreLink />
      </div>
    </div>
  );
}
