
/*
 * @Author: maofanghai
 * @Date: 2024-03-19 18:38:21
 * @LastEditTime: 2024-05-29 20:37:45
 */
class FTCommonClass {
  showAd: any;  // showAd记录
  setShowAd(showAd: boolean) {
    this.showAd = showAd;
  }
}

export default new FTCommonClass();
