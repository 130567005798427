import React from 'react'
import styles from './FTSkeleton.module.scss'
import clsx from 'clsx'
import cuid from 'cuid'
import { imageLoadingClassName } from 'src/utils'

interface IProps {
  num?: number
}

export default function FTSkeleton(props: IProps) {
  const {num = 9} = props
  const arr = new Array(num).fill(0)
  return (
    <div className={styles.FTSkeleton}>
      {
        arr.map((item, index) => {
          return <div className={styles.item} key={index}>
            <div className={clsx(imageLoadingClassName(), styles.itemInnerBox)}></div>

          </div>
        })
      }
    </div>
  )
}
