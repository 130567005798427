import React, { useCallback, useEffect } from "react";

export function useRandomList<T>(list: T[],pageSize=4){
  const [showList,setShowList] = React.useState<T[]>([]);
  const [currentPage,setCurrentPage] = React.useState(1);
  const [totalPage,setTotalPage] = React.useState(1);
  useEffect(()=>{
    const totalPage = Math.ceil(list.length/pageSize);
    setTotalPage(totalPage);
    const currentPageInline = currentPage>totalPage?totalPage:currentPage;
    const start = (currentPageInline-1)*pageSize;
    const end = currentPageInline*pageSize;
    setShowList(list.slice(start,end));
  },[currentPage, list, pageSize]);

  const nextPage = useCallback(()=>{
    let nextPage = currentPage+1;
    if(nextPage>totalPage){
      nextPage = 1;
    }
    setCurrentPage(nextPage);
  },[currentPage, totalPage])
  
  const prevPage = useCallback(()=>{
    let prevPage = currentPage-1;
    if(prevPage<1){
      prevPage = totalPage;
    }
    setCurrentPage(prevPage);
  },[currentPage, totalPage])

  return {
    showList,
    currentPage,
    totalPage,
    nextPage,
    prevPage
  }

}