import utils from "./utils";

export function imageLoader(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => {
      reject(e);
    };
  });
}

export function cloneCanvasWithMaxSide(
  canvas: HTMLCanvasElement,
  maxSide: number
) {
  const { width, height } = canvas;
  const scale = utils.aspectFitScale(width, height, maxSide);
  const newWidth = Math.floor(width * scale);
  const newHeight = Math.floor(height * scale);
  if (width === newWidth && height === newHeight) {
    return canvas;
  }
  const newCanvas = document.createElement("canvas");
  newCanvas.width = newWidth;
  newCanvas.height = newHeight;
  const ctx = newCanvas.getContext("2d");
  ctx?.drawImage(canvas, 0, 0, width, height, 0, 0, newWidth, newHeight);
  return newCanvas;
}

export function downloadFile(url, onProgress?: (progress: number) => void) {
  return new Promise<Blob>((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onprogress = function (e) {
      const percent = Math.floor((e.loaded / e.total) * 100); // 下载进度
      onProgress?.(percent);
    };
    xhr.send();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      if (xhr.status === 200) {
        const response = xhr.response;
        if (response instanceof Blob) {
          resolve(response);
          return;
        }
        if (response instanceof ArrayBuffer) {
          resolve(new Blob([response]));
          return;
        }
      }
      reject(new Error(xhr.statusText));
    };
  });
}

export function canvasToBlob(canvas, type, quality) {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      type,
      quality
    );
  });
}


export function cloneCanvasWithSize(res:HTMLCanvasElement|HTMLImageElement,width,height){
  const newCanvas = document.createElement("canvas");
  newCanvas.width = width;
  newCanvas.height = height;
  const ctx = newCanvas.getContext("2d");
  ctx?.drawImage(res, 0, 0, res.width, res.height, 0, 0, width, height);
  return newCanvas;
}

export function canvasToImage(canvas:HTMLCanvasElement){
  const img = new Image();
  img.src = canvas.toDataURL();
  return img;
}