/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2022-10-31 09:47:28
 * @Description:
 */
// Import Swiper styles
import "swiper/modules/effect-coverflow/effect-coverflow.scss";
import "swiper/swiper.scss";
import "swiper//modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import "./FTNoImageLinkToAIGC.scss";

// import required modules
import { Autoplay, Pagination } from "swiper";
import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { actionAdClickUp, actionAdShow } from "src/analyze/actions";

import { FTGetA8s } from "src/server/api";
import FTNoImageSkedon from 'src/components/FTNoImageMobile/FTNoImageSkedon';
import { getAIGCLink } from "src/utils";
import urls from "src/constant/url";
import { useLanguage } from "src/hooks";

const initList = [
  {
    image: urls.assetsUrl + "/img/aigc/banner-av.jpg",
    url: getAIGCLink("toAvatar"),
    type: "avatar"
  },
  {
    image: urls.assetsUrl + "/img/aigc/banner-text.jpg",
    url: getAIGCLink("textToImage"),
    type: "text2img"
  }
  // ,
  // {
  //   image: urls.assetsUrl + "/img/aigc/banner-photo.jpg",
  //   url: getAIGCLink("imageToImage"),
  //   type: "img2img"
  // }
];
export default function FTNoImageLinkToAIGC() {
  const [analyticObj, setAnalyticObj] = React.useState<any>({});
  const [a8sData, setA8sData] = React.useState<any>({});
  const [list, setList] = React.useState<any>([]);
  const { language } = useLanguage();
  useEffect(() => {
    if (list.length > 0) {
      actionAdShow({
        location: "goart_web_mobile_banner",
        button_position: `${list[0].type}_banner`,
        jump_url: list[0].url
      });
      setAnalyticObj({
        ...analyticObj,
        [list[0].type]: true
      });
    }
  }, []);
  useEffect(() => {
    FTGetA8s({
      types:"goartBanner",//hubAItoolsBanner,goartBanner
    }, language).then(
      (res) => {
        // setA8sData(data);
        console.log(
          "hubAItoolsBanner",
          res,
          process.env.REACT_APP_PUB_STATIC_URL
        );
        if (res.data?.advertises?.length > 0) {
          const list = res.data.advertises.map((item) => {
            return {
              image: process.env.REACT_APP_PUB_STATIC_URL + item.cover,
              url: item.jumpUrl + "?utm_source=goart",
              type: item.buttonText
            };
          });
          setAnalyticObj({
            ...analyticObj,
            [list[0].type]: true
          });
          setList(list);
        }
      }
    );
  }, [language]);
  const preventDefault = (e: any) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
  };
  const handleClick = (e: any, value: any) => {
    preventDefault(e);
    window.open(value.url);
    actionAdClickUp({
      location: "goart_web_mobile_banner",
      button_position: `${value.type}_banner`,
      jump_url: value.url
    });
  };
  if(list.length === 0){
    return null;
  }
  return (
    <>
      <div className='mySwiper'>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          pagination={{
            clickable: true,
            bulletActiveClass: "bullet-active",
            bulletClass: "bullet-default"
          }}
          modules={[Autoplay, Pagination]}
          onSlideChange={(e) => {
            const value = list[e.activeIndex];
            setTimeout(() => {
              if (value&&!analyticObj[value.type]) {
                actionAdShow({
                  location: "goart_web_mobile_banner",
                  button_position: `${value.type}_banner`,
                  jump_url: value.url
                });
                setAnalyticObj({
                  ...analyticObj,
                  [value.type]: true
                });
              }
            }, 1000);
          }}>
          {list.map((item: any) => {
            return (
              <SwiperSlide
                key={item.image}
                onMouseDown={preventDefault}
                onClick={(e) => handleClick(e, item)}>
                <img src={item.image} alt='' />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {list.length === 0 && <FTNoImageSkedon />}
      </div>
      
    </>
  );
}
