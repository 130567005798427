/*
 * @Author: lhg
 * @Date: 2023-4-20
 * @Description:
 */
import React, { memo, useEffect, useState } from "react";
import { openAppOrToDeeplink, utils } from "src/utils";

import ReactDOM from "react-dom";
import link_to_app_image from "src/assets/img/dialog_open_app/link_to_app_image.jpg";
import styles from "./DialogOpenApp.module.scss";
import { useLanguage } from "src/hooks";
import { actionOpenVisitApp, getPath } from "src/analyze/actions";

const DialogOpenApp = (props: any): JSX.Element => {
  const [show, setShow] = useState(true);
  const [isMobile, setIsMobile] = React.useState(false);
  const { t } = useLanguage();

  // 关闭
  function close() {
    setShow(false);
  }

  // 打开或下载 app
  function openApp() {
   // show埋点
    // actionOpenVisitApp('entry_popup') 应该埋点击事件的点位 fotor_web$click_up$drownload_app_popup
    if(window.mobileCommon){
      window.mobileCommon.openFotorAppByAdjustLink({
        adgroup: "enter_popup",
        campaign: window.location.hostname+ window.location.pathname,
      });
    }else{
      openAppOrToDeeplink("dialog_open_app")
    }
    setTimeout(()=>{
      close()
    },1000)
  }

  useEffect(() => {
    setIsMobile(utils.myBrowserOS().isMobile);
  }, []);

  return !show || !isMobile ? (
    <></>
  ) : (
    ReactDOM.createPortal(
      <div className={styles.wrap}>
        <div className={styles.container}>
          {/* 关闭按钮 */}
          <div className={styles.btn_close} onClick={() => close()}>
            <div className={styles.icon}></div>
          </div>
          {/* banner 图片 */}
          <div className={styles.img_wrap}>
            <img className={styles.img} src={link_to_app_image} alt='' />
          </div>
          {/* 底部内容 */}
          <div className={styles.info}>
            <div className={styles.title}>{t("mobile_enter_pop_title")}</div>
            <div className={styles.desc}>{t("mobile_enter_pop_desc")}</div>
            {/* 按钮组 */}
            <div className={styles.bottom}>
              <button
                id='goart_dialog_ad_btn_cancel'
                className={styles.btn_cancel}
                onClick={() => close()}>
                {t("mobile_enter_pop_cancel")}
              </button>
              <button
                id='goart_dialog_ad_btn_get_to_app'
                className={styles.btn_open_app}
                onClick={() => openApp()}>
                {t("mobile_enter_pop_open_app")}
              </button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};

export default DialogOpenApp;
