import "./ToolLeft.scss";

import FTImageStore from "src/store/FTImageStore";
/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 15:37:43
 * @Description:
 */
// @ts-nocheck
import React from "react";
import ToolLeftEffectList from "./ToolLeftEffectList/ToolLeftEffectList";
import ToolLeftSearch from "./ToolLeftSearch/ToolLeftSearch";
import { useUploadImage } from "src/hooks";
interface IProps {
  className: string;
}

export default function ToolLeft(props: IProps) {
  let { className } = props;
  let uploadImage = useUploadImage();

  const handleUpload = () => {
    FTImageStore.uploadImage("goart_left_change_image");
  };
  return (
    <div className={`toolleft ${className}`}>
      <ToolLeftSearch />
      <ToolLeftEffectList />
      {/* {!uploadImage && (
        <div className='toolleft_mask' onClick={handleUpload}></div>
      )} */}
    </div>
  );
}
