/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-01 09:17:24
 * @Description:
 */

import React from "react";
import "./FTButton.scss";
interface IProps {
  className?: string;
  type?: "" | "primary";
  onClick?: (e?: any) => any;
  disabled?: boolean;
}
class FTButton extends React.Component<IProps, {}> {
  render() {
    let { className, type, children, onClick, disabled } = this.props;
    return (
      <button
        className={`ft_btn ${className || ""} ${type || ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}

export default FTButton;
